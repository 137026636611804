import { combineReducers } from "redux";

import lessonReducer from "./lesson/lesson.reducer";
import messageReducer from "./message/message.reducer";
import dataReducer from "./data/data.reducer";
import settingReducer from "./setting/setting.reducer";
import flashReducer from "./flash/flash.reducer";

export default combineReducers({
  lesson: lessonReducer,
  message: messageReducer,
  data: dataReducer,
  setting: settingReducer,
  flash: flashReducer,
});
