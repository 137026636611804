import { Button } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
// import { getArticle } from "src/actions/actions-teacher/articleActions";
// import ShareIcon from "src/assets/share.png";
// import Page from "src/components/Page";

// import LinearProgress from "../../LinearProgess";
import MainPoints from "../../MainPoints";
import SoundSmart from "../../SoundSmart";
// import Teachers from "../../Teachers";
// import Voting from "../../Voting";
// import Kialo from "./circle";
import CovidVaccineArticleImg from "./covid_article_img.png";

const BlogPost = (props) => {
  const [height, setHeight] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [slug, setSlug] = useState("covid-vaccine");
  const [positions, setPositions] = useState([]);
  const arg1 = useRef(null);
  const arg2 = useRef(null);
  const arg3 = useRef(null);
  const arg4 = useRef(null);
  const arg5 = useRef(null);
  const arg6 = useRef(null);

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", listenScrollEvent);

    props.getArticle("covid-vaccine");

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  useEffect(() => {
    setProfiles(props.article?.article?.profiles);
    const postionsArr = [
      arg1.current.offsetTop - 200,
      arg2.current.offsetTop - 200,
      arg3.current.offsetTop - 200,
      arg4.current.offsetTop - 200,
    ];
    setPositions(postionsArr);
    console.log(postionsArr);
  }, [props.article?.article]);

  const listenScrollEvent = () => {
    setHeight(window.scrollY);
  };

  return (
    // <Page
    //   title="We have an effective vaccine against COVID-19. But is it ethical?"
    //   description="The year 2020 will go down in history as the year when something smaller than 1mm in length brought the world to a shattering halt and cost the lives of over 2 million people. It was a year of devastating destruction of people’s lives and the economy. Yet, it is also a year of incredible scientific achievement and global cooperation with a few safe and effective vaccines having been developed and approved for medical use within just one year of the pandemic starting. Now the challenge is a logistical one: getting 7 billion people vaccinated as soon as possible. It is also a moral challenge - how to test vaccines so that the most important ethical standards of science are upheld? And how to distribute them in a fair (equal?) way? Therefore, the question is whether the COVID-19 vaccine is ethical."
    // >
    <div className="blog">
      {/* <LinearProgress height={height} maxHeight={positions[3]} /> */}
      <div className="blog__container">
        <div className="blog__content">
          <h6>Medicine</h6>
          <h1>
            We have an effective vaccine against COVID-19. But is it ethical?
          </h1>
          <h3 style={{ position: "relative" }}>
            The year 2020 will go down in history as the year when something
            smaller than 1mm in length brought the world to a shattering halt
            and cost the lives of over 2 million people. It was a year of
            devastating destruction of people’s lives and the economy. Yet, it
            is also a year of incredible scientific achievement and global
            cooperation with a few safe and effective vaccines having been
            developed and approved for medical use within just one year of the
            pandemic starting. Now the challenge is a logistical one: getting 7
            billion people vaccinated as soon as possible. It is also a moral
            challenge - how to test vaccines so that the most important ethical
            standards of science are upheld? And how to distribute them in a
            fair (equal?) way? Therefore, the question is whether the COVID-19
            vaccine is ethical.
          </h3>
          <MainPoints
            arguments={[
              "The rapid development of the COVID-19 vaccines is an unprecedented event in the medical history of the world, however, it created a number of ethical dilemmas",
              "Some very difficult ethical decisions were made in the face of the trade-off of efficiency and safety during the trial stage",
              "The production and roll-outs of the vaccines are highly unequal and there exists solutions to this",
            ]}
          />

          <div className="blog__content__buttons">
            <div>
              <h6>April 21, 2021</h6>
              <p>{props.article?.article?.time} minutes read</p>
            </div>
            {/* <Button
                startIcon={<TodayIcon />}
                color="secondary"
                variant="outlined"
                component={RouterLink}
                to={`/student/results/?slug=${slug}`}
              >
                Book a lesson
              </Button> */}
            {/* <Button
                startIcon={
                  <img
                    src={ShareIcon}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              >
                Share
              </Button> */}
          </div>
          <h3 style={{ position: "relative" }}>VACCINE TRIALS</h3>

          <p className="paragraph">
            For a vaccine to reach the mass market, it must have successfully
            gone through many stages of testing, from cells to small animals to
            healthy human volunteers before finally being officially rolled out
            once approved by national health boards as being safe (no evidence
            of severe side effects) and effective. How these human trials are
            conducted – the study methodology – is extremely important when
            assessing and critically interpreting the findings/results produced.
            The gold standard are double-blind randomised controlled trials
            where neither the doctors nor the volunteers know whether they are
            receiving the placebo or the actual vaccine. The intense time
            pressure for development of a vaccine against COVID meant that
            unlike most vaccine and drug development, its trials could not
            afford to take years to complete. The question to be answered then
            is whether the COVID-19 vaccine trials were conducted ethically.
          </p>
          <SoundSmart
            arguments={[
              "Some countries will not vaccinate any meaningful portion of their society by 2023 ",
              "Revoking patent rights might massively reduce inequalities in the access to vaccines",
            ]}
          />
          <h2>FOR</h2>
          <h3 style={{ position: "relative" }} ref={arg1}>
            All trials were conducted in good faith
          </h3>
          <p className="paragraph">
            March 2020 saw research groups around the world flurrying into
            action to make a COVID-19 vaccine that was both safe and effective
            as soon as possible with the governments of wealthy countries
            pouring huge amounts of money into these projects in the race
            against an ever-worsening pandemic. The fact that trials need to
            provide robust, reliable evidence of vaccine efficacy but do this as
            quickly as possible creates an inherent trade-off to be resolved in
            the testing process. While it is true that the trials’ volunteers
            have been given untested medical products that might potentially
            have adverse effects on their health, the{" "}
            <span className="tooltiop">
              distinction here is that the vaccine only has a “potential” to
              cause harm versus the undeniable known risk of harm of a COVID-19
              infection.
            </span>
            As such, it seems justifiable - in these unprecedented circumstances
            - to provide a vaccine that was less robustly tested than other
            similar vaccines in the past.
          </p>
          <p className="paragraph-2">
            Most of the trials involved a “natural infection” approach allowing
            participants to go about their normal activities and monitoring for
            development of the symptoms in the vaccine and placebo group. This
            might seem unethical as this approach requires some people to catch
            the virus. Nonetheless, vaccine trials focused on young, healthy
            consenting adults. Given it was relatively clear at the time of the
            trials that these characteristics significantly mitigate the risk of
            any severe symptoms of COVID-19, it might be argued that the trials
            successfully resolved the trade-off of efficacy and safety and the
            research groups acted in good faith.
          </p>

          <h2>AGAINST</h2>

          <h3 style={{ position: "relative" }} ref={arg2}>
            Participants’ could not have made an informed decision
          </h3>
          <p className="paragraph">
            It is a medical standard that research groups need to meticulously
            inform trials’ participants of all possible harmful consequences and
            the uncertainty surrounding given medical product. The reason for
            this is the importance of obtaining an informed, meaningful consent
            from potential trialists. Insofar as the exact details of all
            trials’ proceedings are not public, there are many reasons to
            believe that participants’ consent might be questionable.
          </p>
          <p className="paragraph-2">
            Firstly, the COVID-19 vaccines are not just any other medical
            product. They have been developed to stop what has been the largest
            transformation of our daily lives in the past few decades. Clearly,
            this ubiquitous impact of the coronavirus might have influenced the
            decision process of trials’ participants. On the one hand, they have
            been bombarded with the frightening numbers of cases and victims
            every day for the past year. On the other hand, as the measures to
            stop the virus failed miserably all around the world it was the
            development of a successful vaccine that seemed to be our last hope.
            Even with the best intentions, one could argue that making an
            informed decision in such circumstances is likely to be impossible
            and, as such, the trials violated the obligation to obtain patients’
            consent.{" "}
            <span className="tooltiop">
              To use an analogy, we would not accept the consent to transplant
              one’s kidney after one was forced to watch hundreds of graphic,
              emotional videos displaying the pain of children without kidneys.
            </span>
          </p>
          <p className="paragraph-2">
            Secondly, unlike most other medical products, the COVID-19 vaccines
            are meant to fight against a highly infectious disease. If some of
            the participants that received placebos got infected with the
            COVID-19 thinking they are immune to this because of the vaccine,
            they might have been potentially dangerous. This means that the
            trials’ participants ‘going about their lives as normal is a threat
            posed not only to themselves but also to their families, friends and
            everyone they encounter on a daily basis. While some countries have
            had heavy lockdown restrictions limiting people’s contacts, this was
            not the case in all places where trials took place. Given that most
            trials were double-blind – meaning participants did not know if they
            were vaccinated or given a placebo, it might suggest that trialists’
            could not have been entirely sure whether they would be threatening
            their social circles. As such, they consented to participate in
            studies that also had effects on their families, friends or
            colleagues and these people did not have any say in this.
          </p>

          {/* <Teachers slug={slug} /> */}
          <h3 style={{ position: "relative" }}>
            VACCINE PRODUCTION AND ROLL-OUTS
          </h3>
          <h2>FOR</h2>
          <h3 style={{ position: "relative" }} ref={arg3}>
            The vaccine was created through the worldwide cooperation
          </h3>

          <p className="paragraph">
            Historically, the private and public sectors have been rather bad in
            fostering successful corporations to tackle major global issues. For
            instance, one paper published in Nature established that malaria
            nets have been proven to be an extremely effective measure, averting
            approximately 663 million cases of malaria over the period
            2000–2015. In spite of this overwhelming evidence for the
            life-saving potential of something so cheap (one net is just 3$),
            there is still a number of countries that, due to economic reasons,
            cannot afford to provide their citizens with massively-distributed
            nets and very little has been done to help them. With the COVID-19
            vaccine, this has not been the case.{" "}
            <span className="tooltip">
              Over the span of just a year, a number of countries, companies and
              universities joined forces to create many vaccines.
            </span>{" "}
            While politics played a role in this, it is commendable that a
            number of different stakeholders contributed to the funding of the
            vaccines as illustrated by the graphic created by the BBC.
          </p>
          <h2>AGAINST</h2>

          <h3 style={{ position: "relative" }} ref={arg4}>
            The most vulnerable lack access to the vaccines
          </h3>

          <p className="paragraph">
            One can argue that the elderly and those with health conditions that
            result in them having significantly weakened immune systems (such as
            cancer or transplant patients) have been placed at the top of the
            priority list to receive their vaccine as this population group
            represents the vast majority of the disease-related deaths.
            Healthcare workers have also been placed as a high priority group.
            These people are putting themselves at high risk everyday of not
            only catching and becoming themselves infected but also of spreading
            the coronavirus around their place of work potentially infecting
            many other vulnerable people (in hospitals and care homes). However,
            this is only the bare minimum that ought to be expected from the
            decision-makers. The economic inequality in the access to the
            vaccines is rampant.{" "}
            <span className="tooltip">
              The Economist predicts that some countries in Africa, Asia and
              Latin America will be able to vaccinate significant portions of
              their society as late as early 2023.{" "}
            </span>
            This is particularly important given that the economic situation
            directly influences the vulnerability to the COVID-19. The countries
            with less-developed healthcare are understaffed, have limited access
            to protective equipment and, most crucially, respirators that are
            needed in the most severe cases.
          </p>
          <img
            src={CovidVaccineArticleImg}
            style={{ width: "100%", maxWidth: "593px", margin: "2rem 0" }}
          />
          <p className="paragraph-2">
            Furthermore, the required time off work leads to staff shortages in
            already very stretched and understaffed hospitals. Because of this
            some people would consider vaccinating healthcare workers as an even
            greater priority than the elderly. Finally, a more controversial
            priority group choice could be younger people (16-24 years age
            group). Although no countries have adopted this method, this is the
            population group causing the greatest spread of infection and thus
            vaccinating this group first could have the potential to reduce
            transmission of the virus the quickest. However, when the vaccine
            was initially rolled out there was limited evidence on the effect of
            the vaccine on reducing transmission rates and given young people
            have the lowest “clinical need” for the vaccine they have,
            generally, been deemed lowest in the priority ranking to receive a
            vaccine.
          </p>

          <p className="paragraph-2">
            It is difficult to claim that the production and roll-out processes
            are ethical given that it is the economic greed and malicious
            political incentives that are responsible for the discussed
            inequality. There was significant tension between the UK and the EU
            with regards to the vast majority of the AstraZeneca vaccine being
            shipped from Europe where it was being produced to the UK who had
            the ownership of the vaccine due to its development in an Oxford
            research centre. As a result, the UK has already vaccinated a very
            high portion of their society while even powerful countries like
            Germany are significantly behind. Furthermore, developing countries
            cannot afford to buy large quantities of the vaccines to distribute
            amongst their people.{" "}
            <span className="tooltip">
              Can one really justify prioritising the vaccination of reasonably
              healthy 50-year-olds in the UK over the elderly, immunocompromised
              and malnourished in other countries around the world?
            </span>
          </p>

          <p className="paragraph-2">
            There are, at the very least, a few solutions that would easily
            mitigate the economic inequality of the vaccines roll-outs. The most
            ambitious plan would be to globally centralise and coordinate the
            production and distribution of the vaccines whereby all countries
            would contribute financially to these according to their
            capabilities but receive the doses based on their needs. This would
            be obviously very contentious from the political standpoint.
            <span className="tooltip">
              But the easier alternative is just to revoke the patent rights for
              vaccines making the production all over the world possible.
            </span>{" "}
            This would likely increase the supply of vaccines and, as a result,
            bring down the price. To date, not a single powerful politician
            backed this rather commonsensical idea.
          </p>

          {/* <p className="paragraph-2">
              Note that almost all new post-pandemic elements of the world of
              work are dependent on human decisions rather than objective
              factors. Think what decisions are most likely to be taken!
            </p>
            <Voting
              text={
                "Note that almost all new post-pandemic elements of the world of work are dependent on human decisions rather than objective factors. Think what decisions are most likely to be taken!"
              }
            /> */}
        </div>
        {/* {window.innerWidth > 1180 && (
            <Kialo
              height={height}
              slug={slug}
              positions={!isEmpty(positions) ? positions : []}
            />
          )} */}
      </div>
    </div>
    // </Page>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  article: state.article,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(BlogPost);
