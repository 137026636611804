import { Button } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
// import { getArticle } from "src/actions/actions-teacher/articleActions";
// import ShareIcon from "src/assets/share.png";
// import Page from "src/components/Page";

// import LinearProgress from "../../LinearProgess";
import MainPoints from "../../MainPoints";
import SoundSmart from "../../SoundSmart";
// import Teachers from "../../Teachers";
// import Voting from "../../Voting";
// import Kialo from "./circle";

const BlogPost = (props) => {
  const [height, setHeight] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [slug, setSlug] = useState("word-of-work");
  const [positions, setPositions] = useState([]);
  const arg1 = useRef(null);
  const arg2 = useRef(null);
  const arg3 = useRef(null);
  const arg4 = useRef(null);
  const arg5 = useRef(null);
  const arg6 = useRef(null);

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", listenScrollEvent);

    // props.getArticle("word-of-work");

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  useEffect(() => {
    setProfiles(props.article?.article?.profiles);
    const postionsArr = [
      arg1.current.offsetTop - 200,
      arg2.current.offsetTop - 200,
      arg3.current.offsetTop - 200,
      arg4.current.offsetTop - 200,
      arg5.current.offsetTop - 200,
      arg6.current.offsetTop - 200,
    ];
    setPositions(postionsArr);
    console.log(postionsArr);
  }, [props.article?.article]);

  const listenScrollEvent = () => {
    setHeight(window.scrollY);
  };

  return (
    // <Page
    //   title="Will the pandemic have good consequences for the world of work?"
    //   description="In only a few months, the coronavirus pandemic has upended the daily lives of people around the world. For Americans, the economic impact of the virus has led to new categorizations of “essential” workers, a large-scale move to remote work and skyrocketing unemployment that is expected to continue increasing. With more than 30 million people filing for unemployment in the first weeks of the pandemic, the U.S. is still predicted to experience a coronavirus-induced recession throughout the whole 2021. The situation is no different across the globe. Will the pandemic have good consequences for the world of work?”"
    // >
    <div className="blog">
      {/* <LinearProgress height={height} maxHeight={positions[5]} /> */}
      <div className="blog__container">
        <div className="blog__content">
          <h6>Economy</h6>
          <h1>
            Will the pandemic have good consequences for the world of work?
          </h1>
          <h3 style={{ position: "relative" }}>
            In only a few months, the coronavirus pandemic has upended the daily
            lives of people around the world. For Americans, the economic impact
            of the virus has led to new categorizations of “essential” workers,
            a large-scale move to remote work and skyrocketing unemployment that
            is expected to continue increasing. With more than 30 million people
            filing for unemployment in the first weeks of the pandemic, the U.S.
            is still predicted to experience a coronavirus-induced recession
            throughout the whole 2021. The situation is no different across the
            globe. Will the pandemic have good consequences for the world of
            work?
          </h3>
          <MainPoints
            arguments={[
              "The pandemic transformed the world of work, immensely accelerating some existing trends and creating wholly new ideas.",
              "The way various companies and job places dealt with the challenges of COVID-19 varies greatly and showcases massive class differences around the world.",
              "The pandemic will transform the world of work forever. It is yet unclear whether this transformation will have good consequences.",
            ]}
          />

          <div className="blog__content__buttons">
            <div>
              <h6>April 10, 2021</h6>
              <p>{props.article?.article?.time} minutes read</p>
            </div>
            {/* <Button
                startIcon={<TodayIcon />}
                color="secondary"
                variant="outlined"
                component={RouterLink}
                to={`/student/results/?slug=${slug}`}
              >
                Book a lesson
              </Button> */}
            {/* <Button
                startIcon={
                  <img
                    src={ShareIcon}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              >
                Share
              </Button> */}
          </div>

          <p className="paragraph">
            In only a few months, the coronavirus pandemic has upended the daily
            lives of people around the world. For Americans, the economic impact
            of the virus has led to new categorizations of “essential” workers,
            a large-scale move to remote work and skyrocketing unemployment that
            is expected to continue increasing. With more than 30 million people
            filing for unemployment in the first weeks of the pandemic, the U.S.
            is still predicted to experience a coronavirus-induced recession
            throughout the whole 2021. The situation is no different across the
            globe. Will the pandemic have good consequences for the world of
            work?
          </p>
          <SoundSmart
            arguments={[
              "Only 12% want to return to full-time in-person work.",
              "COVID-19 might be the best thing that has happened for female employees in a long time.",
              "The pandemic could mean a new definition of class whereby what becomes the most important factor influencing your standing in a society is whether you can do your job remotely.",
            ]}
          />
          <h2>FOR</h2>
          <h3 style={{ position: "relative" }} ref={arg1}>
            It will drive innovation and modernisation{" "}
          </h3>
          <p className="paragraph">
            If we can move past decades of orthodoxy about 9-to-5,
            office-centric work, there’s an opportunity to retain the best parts
            of office culture while freeing ourselves from bad habits and
            inefficient processes, from ineffective meetings to unnecessary
            bureaucracy. Every leader believes they can do better, and things
            can move faster: this is their chance.
          </p>
          <p className="paragraph-2">
            From the employee perspective, the shift is massive and very
            consequential: people are making new choices about where they want
            to live and creating new expectations about flexibility, working
            conditions and work life balance that can’t be undone. Our Future
            Forum research of 4,700 knowledge workers found the majority never
            want to go back to the old way of working.
          </p>
          <blockquote className="groucho">
            Only 12% want to return to full-time office work, and 72% want a
            hybrid remote-office model moving forward
            <footer>
              <a
                href="https://www.bbc.com/worklife/article/20201023-coronavirus-how-will-the-pandemic-change-the-way-we-work"
                target="_blank"
              >
                Source: BBC
              </a>
            </footer>
          </blockquote>
          <p className="paragraph-2">
            All this change in our methods will go hand-in-hand with a change in
            our tools. Of course, we think Slack has an important role to play
            as a new kind of headquarters for a digital first world, but the
            opportunities for digital transformation are expansive and
            wide-ranging. Businesses that do it well will drive engagement,
            achieve organisational agility, maintain alignment and empower
            teamwork across all disciplines and locations. They will have a
            competitive advantage in this new era of work.
          </p>

          {/* <Teachers slug={slug} /> */}

          <h3 style={{ position: "relative" }} ref={arg2}>
            The pandemic will help female employees
          </h3>
          <p className="paragraph">
            Even as modern organisations are challenged by attracting, retaining
            and promoting talented employees, they systematically underutilize
            one major source of available talent: women. Women account for half
            of all entry-level employees, yet they compose only a third of
            senior managers and a fifth of C-suite executives. One of the
            reasons women have a harder time advancing professionally is that
            they are much more likely than men to prioritise their family
            responsibilities over their careers as proven in The Pew Research
            Center massive survey of employees all around the world.
          </p>
          <p className="paragraph-2">
            Giving employees more flexibility in choosing when and where they
            work can increase gender equality via two pathways. First, research
            has long established that{" "}
            <span className="tooltip">
              remote work can help mothers better balance their work and family
              responsibilities, which makes them less likely to sacrifice one
              for the other.
            </span>{" "}
            Second, data collected during the pandemic suggests that working
            from home may also make the father more involved. More couples share
            family responsibilities more equally now than they did before the
            pandemic, according to a survey of American couples. In a survey of
            Canadian fathers, a majority report doing more household chores and
            spending more time with their children now than they did before the
            pandemic.
          </p>

          <p className="paragraph-2">
            If organisations continued to offer remote work opportunities after
            the pandemic is over, more women will have a level playing field.
          </p>

          <h3 style={{ position: "relative" }} ref={arg3}>
            We might rediscover what we like in our work
          </h3>
          <p className="paragraph">
            To me, the most significant realisation due to the pandemic and
            related restrictions, has been that people have become aware of the
            – call it ‘social’ or ‘intrinsic’ – value of work in our lives.{" "}
            <span className="tooltip">
              For many, those much loathed and dreaded three words – ‘going to
              work’ – is something they crave.
            </span>{" "}
            Even the admittedly small fraction of domestic workers who continued
            to be paid through the lockdown were restless to resume work.
          </p>
          <p className="paragraph-2">
            For different reasons, we’re socialised into thinking that work is
            about money. “Work-from-home” people have continued to enjoy the
            economic value of work, but they still feel like there is a hole in
            their lives. But maybe overall, we will approach our work with more
            positivity. “For a long time, we’ve probably taken for granted the
            ability to see our coworkers every day and maybe didn’t realize how
            valuable that was,” says Lakshmi Rengarajan, a workplace connection
            consultant formerly of WeWork and Match.com. “I think teams will be
            a lot closer when they’re able to move back into the workplace”.
          </p>

          <h2>AGAINST</h2>
          <h3 style={{ position: "relative" }} ref={arg4}>
            It will increase the class divide
          </h3>
          <p className="paragraph">
            For those who can work from home (approximately 40% of US workers
            largely from the higher educated quartile), our daily experience of
            work will change significantly. Commuters will gain an hour back on
            average in their day and estimates suggest that post pandemic, some
            portion of the week will involve working from home – from one to
            three days a week.
          </p>
          <p className="paragraph-2">
            But the greatest challenge that we face regarding work is what
            happens to the other 60% of workers who can’t work from home.{" "}
            <span className="tooltip">
              {" "}
              The decline in daily commute as well as business travel has a
              knock-on effect on those whose jobs support and serve these
              workers and offices.
            </span>{" "}
            One in four workers are in the transportation, food service,
            cleaning and maintenance, retail and personal care industries. These
            jobs, often concentrated in cities and lower paid, are disappearing
            or are at risk of disappearing in the near term. Without shoring up
            the social safety net and investing in ways to further skills, the
            fate of such workers is doomed.
          </p>

          <p className="paragraph-2">
            The class divide will increase in a myriad of diverse ways. Health
            and legal experts predict that on-the-job medical screening, such as
            temperature checks and antibody tests, will be a reality for those
            who return to work in the months ahead. But the access to these -
            certainly helpful - precautions is almost guaranteed to be
            influenced by one’s financial standing.
          </p>
          <h3 style={{ position: "relative" }} ref={arg5}>
            The popularity of remote work will hit our mental health
          </h3>
          <p className="paragraph">
            The work place is a social environment and business in any form is a
            social phenomenon. Without face-to-face engagement, and those casual
            meetings round the coffee machine, the ‘flow’ that makes things
            work, and work fast, will be missing. Work groups quickly lose
            focus, and the sense of belonging – and of commitment to the
            organisation and its aims and objectives – is very quickly lost.
          </p>
          <p className="paragraph-2">
            Furthermore, we have been in the midst of a loneliness epidemic
            among the 20-somethings for the better part of the last two decades.
            It is a particular problem for young new graduates moving to an
            unfamiliar city on their first job. With no family or friends
            nearby, work is the only place they can find friends and arrange
            social events. “We come in to work to see our friends!” has been
            their response to surveys.
          </p>
          <p className="paragraph-2">
            Last but not least, the digital world of Zoom and Skype is no
            substitute for face-to-face meetings. It is easy to hide away
            reading your emails and newsfeed. People find the virtual
            environment awkward and very quickly get bored. There is a very
            strict limit on the size of natural conversations for four people.
            Anything bigger, and it becomes a lecture dominated by a handful of
            extraverts.
          </p>
          <h3 style={{ position: "relative" }} ref={arg6}>
            Presenteeism will proliferate
          </h3>
          <p className="paragraph">
            Business travel will virtually cease both within the country and
            between countries as well because people are reluctant to use trains
            and planes and also employers want to minimise travel expenses – so
            Zooming, Skyping, etc. will be the future of business relationships.
            Given the fears of redundancies and a massive increase in job
            insecurity, we will see a great deal of presenteeism - the unhealthy
            expectation to be present in the workplace even though there is no
            need to - over the coming couple of years, which is likely to
            reflect itself in the short term by more visits to the central
            office environment to connect with office politics and to show
            facetime.
          </p>
          <p className="paragraph-2">
            But in the medium term, it will be even more harmful by people
            working longer hours and creating and attending more virtual
            meetings – which will not be good for the health of employees and
            their productivity. And finally, people in management roles will
            have to undergo a major transformation. We will need more managers
            from shop floor to top floor who have emotional intelligence and
            social skills, if we are to manage people more remotely. They will
            be required to identify when people are not coping with their work
            or suffer from mental health problems. In the past, we promoted and
            hired people to leadership roles based on their technical skills; in
            the future, we will need managers who have parity between their
            technical and people skills – this is a major shift in emphasis in
            the new world of work. Nothing suggests everyone will successfully
            face this challenge.
          </p>
          <p className="paragraph-2">
            Note that almost all new post-pandemic elements of the world of work
            are dependent on human decisions rather than objective factors.
            Think what decisions are most likely to be taken!
          </p>
          {/* <Voting
              text={
                "Note that almost all new post-pandemic elements of the world of work are dependent on human decisions rather than objective factors. Think what decisions are most likely to be taken!"
              }
            /> */}
        </div>
        {/* {window.innerWidth > 1180 && (
            <Kialo
              height={height}
              slug={slug}
              positions={!isEmpty(positions) ? positions : []}
            />
          )} */}
      </div>
    </div>
    // </Page>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  article: state.article,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(BlogPost);
