import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "@material-ui/core";
import MainPoints from "../../MainPoints";
import SoundSmart from "../../SoundSmart";
import Kialo from "./circle";
// import LinearProgress from "../../LinearProgess";
import Voting from "../../Voting";

import TodayIcon from "@material-ui/icons/Today";

import isEmpty from "../../../../validation/is-empty";

import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

const BlogPost = (props) => {
  const [height, setHeight] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [slug, setSlug] = useState("big-tech");
  const [positions, setPositions] = useState([]);
  const arg1 = useRef(null);
  const arg2 = useRef(null);
  const arg3 = useRef(null);
  const arg4 = useRef(null);
  const arg5 = useRef(null);
  const arg6 = useRef(null);
  const arg7 = useRef(null);
  const arg8 = useRef(null);

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", listenScrollEvent);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  useEffect(() => {
    const postionsArr = [
      arg1.current.offsetTop - 200,
      arg2.current.offsetTop - 200,
      arg3.current.offsetTop - 200,
      arg4.current.offsetTop - 200,
      arg5.current.offsetTop - 200,
      arg6.current.offsetTop - 200,
      arg7.current.offsetTop - 200,
      arg8.current.offsetTop - 200,
    ];
    setPositions(postionsArr);
  }, []);

  const listenScrollEvent = () => {
    setHeight(window.scrollY);
  };

  return (
    <div className="blog">
      {/* <LinearProgress height={height} maxHeight={positions[7]} /> */}
      <div className="blog__container">
        <div className="blog__content">
          <h6>Technology</h6>
          <h1>Should Big Tech companies be broken up?</h1>
          <h3 style={{ position: "relative" }}>
            The calls to break up Big Tech are getting louder—and they’re coming
            from both sides of the political aisle. Senator Elizabeth Warren, a
            Democratic presidential contender, says companies like Amazon,
            Facebook, and Google have too much power over our economy, society,
            and democracy. Even Donald J. Trump has said “something is going on
            in terms of monopoly,” and it’s “a bad situation.”
          </h3>
          <MainPoints
            arguments={[
              "Big Tech companies - Facebook, Google, Amazon - have amassed the extent of power that nobody expected.",
              "Their growing influence on our privacy, democracy and market competition creates calls to “break them up” - divide them into a number of smaller companies.",
              "Breaking them up might prove incredibly difficult if not impossible",
            ]}
          />

          {/* <span className="tooltip">
              But is it a good idea, or even possible, to split up these
              companies?
              <div className="tooltiptext">SOUND SMART</div>
            </span>{" "}
            <div className="blog__content--note__container">
              We look into the arguments for and against this idea.
              <div className="blog__content--note">IMPORTANT QUESTION</div>
            </div> */}

          <p className="paragraph">
            Facebook has 2.4 billion active monthly users. Amazon accounts for
            nearly 40 percent of all e-commerce spending in America. Google gets
            more than 92 percent of global search engine inquiries. Apple became
            the first company to cross the $1 trillion mark in market
            capitalization—a market cap larger than the GDP of Saudi Arabia. But
            is it a good idea, or even possible, to split up these companies? We
            look into the arguments for and against this idea.
          </p>
          <SoundSmart
            arguments={[
              "We have Google today because of the legal action against Microsoft in the 90s.",
              "Facebook knows how much you earn.",
              "Big Tech companies are particularly prone to collaborate with the Chinese government.",
            ]}
          />
          <h2>AGAINST</h2>
          <h3 style={{ position: "relative" }} ref={arg1}>
            We value these companies
          </h3>
          <p className="paragraph">
            Something else that may give us pause before breaking up tech
            companies is how much we’ve come to rely on them. Google and
            Facebook have become integrated into our daily lives: the average
            person conducts three to four Google searches and spends 35 minutes
            on Facebook every day. We may be uneasy with the power of tech
            companies, but most of us willingly sign away our rights to get
            access to a service. How many times have you blindly accepted online
            terms and conditions?
          </p>
          <p className="paragraph-2">
            Senator Warren has argued that her plan to break up Big Tech would
            “give people more control over how their personal information is
            collected, shared, and sold” and restrict the advantages analyzing
            our data gives bigger, more established firms. But our data might
            not be the cash cow we think it is. There’s no doubt Big Tech
            companies make buckets of money from using our data, especially for
            advertising purposes—in 2018, Amazon’s ad business surpassed $10
            billion and Google’s ad sales totaled $116 billion—but, says Garrett
            Johnson, assistant professor of marketing at Questrom Business
            School. “These high dollar amounts have caused some people to think
            that their data is a huge pot of gold being stolen from them by
            these evil companies. That’s really an overstatement of what’s going
            on.” For example, on a per-consumer basis, Facebook’s global average
            revenue per user hovers around $25 a year—capping how much your data
            is actually worth. “With over two billion users, collectively, each
            of our $25 helps fund all of the free internet services we enjoy
            [from Facebook]. This notion that we are being robbed of our
            high-value data is really overstated, and I think that’s crucial to
            understand,” says Johnson.
          </p>
          <blockquote className="groucho">
            THIS NOTION THAT WE ARE BEING ROBBED OF OUR HIGH-VALUE DATA IS
            REALLY OVERSTATED, AND I THINK THAT’S CRUCIAL TO UNDERSTAND.
            <footer>GARRETT JOHNSON, ASSISTANT PROFESSOR OF MARKETING</footer>
          </blockquote>

          <h3 style={{ position: "relative" }} ref={arg2}>
            Are Big Tech companies even monopolies?
          </h3>
          <p className="paragraph">
            One of the main purposes of antitrust laws - the kind of legal
            protections that would be used if Big Tech companies were to be
            broken up - is to protect competition and the market. This is
            because when a single company has an extensive share of the market,
            it might be able to unfairly crush the competitors and, as a result
            of that, the market outcome will be harmful for the consumers, e.g a
            monopolist will increase prices. According to Kabrina Chang, a
            clinical associate professor of markets, public policy, and law, the
            first step in determining whether a company has a monopoly is
            defining its market — something that might not be so easy to do with
            modern tech giants. “I don’t know what the market is with Amazon,”
            she says. Jokingly referring to Amazon’s acquisition of Whole Foods
            in 2017, she asks, “Is it groceries now?”
          </p>
          <p className="paragraph-2">
            “What does it mean when Amazon enters healthcare or groceries, or
            Google enters self-driving cars?” asks Marshall Van Alstyne,
            Questrom Professor in Management and coauthor of Platform
            Revolution: How Networked Markets Are Transforming The Economy and
            How To Make Them Work For You. “These are market adjacencies—and you
            want to be able to bring in high quality and innovation to adjacent
            industries, but you might still be relying on the market power of a
            firm that’s incumbent in a narrower industry. The boundaries are
            getting very challenging to define.”
          </p>

          <h3 style={{ position: "relative" }} ref={arg3}>
            It might not help in diminishing their power
          </h3>
          <p className="paragraph">
            The other powerful argument takes a slightly different perspective:
            the problems fueling “break them up” are valid but breaking them up
            is not the solution. To begin with, antitrust enforcement has been
            romanticized well in excess of its accomplishments. The breakup in
            1984 of the monopolistic AT&T into eight companies unleashed
            competition for a time, lowering prices and improving services.
            Eventually, however, as landlines gave way to wireless, the industry
            reconsolidated and regulators relaxed. Today telecom is dominated by
            a reconstituted AT&T along with Verizon, with Sprint as a distant
            third (yet still immense) player. The court-mandated breakup of
            Standard Oil in 1911 was the culmination of the most significant
            antitrust action ever, but the company’s dozens of offshoots
            eventually recombined into massive oil companies that maintain
            tremendous power. (ExxonMobil and Chevron are the two most notable.)
            That breakup also made the wealthy Rockefeller family even
            wealthier, as their shares in one company became shares in
            many—almost all of which doubled quickly and then continued their
            upward trajectory from there.
          </p>
          <p className="paragraph-2">
            Meanwhile, it’s hard to see how going from six companies to 30 would
            give consumers any more choice of services or more control over
            their data, or how it would help to nurture small businesses and
            lower costs to consumers and society. Perhaps there would be
            openings for companies with different business models, ones that
            brand themselves as valuing privacy and empowering individual
            ownership of data. This can’t be ruled out, but the nature of data
            selling and data mining is so embedded in the current models of most
            IT companies that it is very hard to see how such businesses could
            thrive unless they charged more to consumers than consumers have so
            far been willing to pay. In the meantime, the 30 new megacompanies
            would still have immense competitive advantages over smaller
            startups.
          </p>
          <h3 style={{ position: "relative" }} ref={arg4}>
            What about innovation funding?
          </h3>
          <p className="paragraph">
            Breaking up Big Tech would also limit the number and scale of
            acquisitions that these companies can make in any given year. There
            is now an entire venture capital ecosystem that funds and incubates
            companies not so they can go public but so they can be acquired by
            Alphabet, Facebook, Amazon, Apple, or Microsoft (as well as Oracle,
            Salesforce, Intel, and a handful of others). These acquisitions are
            arguably part of the innovation structure, with Big Tech providing
            the same exit capital as public markets, but with less regulatory
            hassle. Limiting acquisitions could have the unintended consequence
            of depressing spending on innovation rather than unlocking it, and
            making it harder for smaller companies to raise money. More
            problematic is how the cap would be determined, or enforced fairly
            and consistently. If Facebook can only make X acquisitions per year
            at Y price, then why shouldn’t ancillary companies like Visa be
            subject to the same rules? Visa may be seen as a financial services
            company, but it enjoys a really strong position in tech, having
            announced the acquisition, just last month, of financial tech
            company Plaid for $5 billion.
          </p>
          <h2>FOR</h2>
          <h3 style={{ position: "relative" }} ref={arg5}>
            Big Tech companies harm innovation
          </h3>
          <p className="paragraph">
            Twenty-five years ago, Facebook, Google, and Amazon didn’t exist.
            Now they are among the most valuable and well-known companies in the
            world. It’s a great story — but also one that highlights why the
            government must break up monopolies and promote competitive markets.
            In the 1990s, Microsoft — the tech giant of its time — was trying to
            parlay its dominance in computer operating systems into dominance in
            the new area of web browsing. The federal government sued Microsoft
            for violating anti-monopoly laws and eventually reached a
            settlement. The government’s antitrust case against Microsoft helped
            clear a path for Internet companies like Google and Facebook to
            emerge.
          </p>
          <p className="paragraph-2">
            The story demonstrates why promoting competition is so important: it
            allows new, groundbreaking companies to grow and thrive — which
            pushes everyone in the marketplace to offer better products and
            services. Aren’t we all glad that now we have the option of using
            Google instead of being stuck with Bing?
          </p>
          <h3 style={{ position: "relative" }} ref={arg6}>
            Not breaking them up limits competition
          </h3>
          <p className="paragraph">
            America’s big tech companies have achieved their level of dominance
            in part based on two strategies:
          </p>
          <p className="paragraph-2">
            Using Mergers to Limit Competition. Facebook has purchased potential
            competitors Instagram and WhatsApp. Amazon has used its immense
            market power to force smaller competitors like Diapers.com to sell
            at a discounted rate. Google has snapped up the mapping company Waze
            and the ad company DoubleClick. Rather than blocking these
            transactions for their negative long-term effects on competition and
            innovation, government regulators have waved them through.
          </p>
          <p className="paragraph-2">
            Using Proprietary Marketplaces to Limit Competition. Many big tech
            companies own a marketplace — where buyers and sellers transact —
            while also participating on the marketplace. This can create a
            conflict of interest that undermines competition. Amazon crushes
            small companies by copying the goods they sell on the Amazon
            Marketplace and then selling its own branded version. Google
            allegedly snuffed out a competing small search engine by demoting
            its content on its search algorithm, and it has favored its own
            restaurant ratings over those of Yelp.
          </p>
          <h3 style={{ position: "relative" }} ref={arg7}>
            Big Tech infringes on our privacy
          </h3>
          <p className="paragraph">
            The stream of Facebook privacy scandals makes us question how much
            the social network and other tech giants actually know about you.
            Their massive size allows them to weaponize network effects and
            effectively know practically everything about us.
          </p>
          <p className="paragraph-2">
            Website Security Baron examined the privacy policies of Facebook,
            Google, Apple, Twitter, Amazon, and Microsoft and put together a
            handy infographic showing the types of data each company admits to
            collecting. For Facebook and others, data is money. But just how
            much these tech giants actually know about you might be surprising.
          </p>
          <p className="paragraph-2">
            As you can see in the infographic below, Facebook is particularly
            data-hungry: It gathers information about your work, income level,
            race, religion, political views, and the ads you click in addition
            to more commonly collected data points such as your phone number,
            email address, location, and the type of devices you use.
          </p>
          <h3 style={{ position: "relative" }} ref={arg8}>
            And they also erode our democracies
          </h3>
          <p className="paragraph">
            Last but not least, one of the arguments in favour of breaking up
            Big Tech is limiting their power over democracies. Activists have
            been sounding the alarm bell for years, particularly after 2017
            Rohingya genocide in Myanmar, when hate speech shared by extremists
            on Facebook fanned the flames of ethnic cleansing, which the
            platform later admitted it did not do enough to prevent. The
            Cambridge Analytica scandal of 2018 brought home to people that
            personal data they had given up so freely to Facebook could be used
            against them. And while the platforms say they are doing all they
            can to scrub misinformation, hate speech and organized violence from
            their sites at scale, they’re often failing. The consequences of
            those so-called online harms appear in the offline world, in ISIS
            recruitment, white-supremacist terror, vaccine skepticism and the
            mainstreaming of conspiracy theories like QAnon.
          </p>
          <p className="paragraph-2">
            And what do you make of these arguments? Which ones do you find most
            convincing? Here is a short argument topology to help you understand
            the piece better!
          </p>
          <Voting
            text={
              "Remember that Big Tech might be problematic in many ways but the REAL QUESTION is whether breaking them up will solve these problems? "
            }
          />
        </div>
        {/* {window.innerWidth > 1180 && (
          <Kialo
            height={height}
            slug={slug}
            positions={!isEmpty(positions) ? positions : []}
          />
        )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(BlogPost);
