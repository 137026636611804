const INIT_STATE = {
  settings: {
    view: 0,
    card: 0,
    status: 0,
  },
};
// status
// 0 - not started
// 1 - in progress
// 2 - finished

const settingsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return {
        ...state,
        settings: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
