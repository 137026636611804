import React, { useState, useRef, useEffect, useCallback } from "react";
import Video from "./video/";
import "./class.styles.scss";
import Navbar from "./navbar/Navbar";
import { Button, ButtonGroup, Snackbar } from "@material-ui/core";
import TabsMenu from "./tabs";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import Popup2 from "./configuration/Popup2";
import Popup1 from "./configuration/Popup1";
import { connect } from "react-redux";
import { setData } from "../redux/data/data.actions";
import { setSettings } from "../redux/setting/setting.actions";
import { setMessages, initMessages } from "../redux/message/message.actions";
import {
  addFlashMessage,
  deleteFlashMessage,
} from "../redux/flash/flash.actions";
import isEmpty from "../validation/is-empty";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import NotFound from "./not-found/NotFound";
import Spinner from "../components/spinner/Spinner";
import socket from "../socket";
import { getLesson } from "../redux/lesson/lesson.actions";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Class = (props) => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const childRef = useRef();
  const [configuration, setConfiguration] = useState(0);

  const [screenSharing, setScreenSharing] = useState(false);

  let query = useQuery();

  const {
    setData,
    setSettings,
    data,
    lesson,
    setting,
    setMessages,
    initMessages,
    addFlashMessage,
    deleteFlashMessage,
  } = props;

  const { view, card } = setting.settings;

  useEffect(() => {
    if (query.get("lessonID")) {
      getLesson();
    } else {
      createLesson();
    }
  }, []);

  useEffect(() => {
    const data = {};
    if (props.lesson.lesson._id) {
      let lessonID = query.get("lessonID")
        ? query.get("lessonID")
        : props.lesson?.lesson._id;
      data.lessonID = lessonID;
      data.userID = query.get("userID");
      data.userName = query.get("name");
      data.role = query.get("role");
      console.log(data);
      console.log(props.lesson.lesson);
      console.log(query.get("lessonID"));
      setData(data);
      socket.emit("join", data);
      socket.emit("initChat", { userID: data.userID, lessonID: lessonID });
      // new message sent
      socket.on("newMsg", (message) => {
        setMessages(message);
        if (message.author !== data.userID) {
          addFlashMessage({
            text: `${message.authorName}: ${message.text}`,
            type: "success",
          });
        }
      });

      socket.on("initChat" + data.userID, (messages) => {
        initMessages(messages);
      });
      // new user logs in, send current settings
      socket.on("newUser", () => {
        addFlashMessage({
          text: `New user has joined the lesson.`,
          type: "success",
        });
        socket.emit("sendSettings", {
          settings: setting.settings,
          room: lessonID,
        });
      });
      socket.on("sendSettings", (data) => {
        setSettings(data.settings);
        console.log("send Settings works");
      });
      socket.on("changeView", (data) => {
        const settings = setting.settings;
        settings.view = data.view;
        setSettings(settings);
        if (data.screenSharing && data.view === 0) {
          setScreenSharing(false);
        } else if (data.screenSharing && data.view === 1) {
          setScreenSharing(true);
        }
      });
      socket.on("changeCard", (data) => {
        const settings = setting.settings;
        settings.card = data.card;
        setSettings(settings);
      });
      socket.on("updateLesson", (data) => {
        getLesson();
      });
      socket.on("endLesson", () => {
        childRef.current.endCall();

        props.history.push(
          `/feedback/?lessonID=${data.lessonID}&&userID=${data.userID}&&role=${data.role}`
        );
      });
    }
    return () => {};
  }, [props.lesson.lesson._id]);

  const getLesson = () => {
    setLoading(true);
    axios
      .get(`/api/v1/lessons/${query.get("lessonID")}`)
      .then((res) => {
        setLoading(false);
        props.setLesson(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createLesson = () => {
    setLoading(true);
    axios
      .post(`/api/v1/lessons/`)
      .then((res) => {
        setLoading(false);
        props.setLesson(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const setView = (view, screenSharing = false) => {
    const settings = setting.settings;
    settings.view = view;
    setSettings(settings);
    socket.emit("changeView", {
      view: view,
      room: query.get("lessonID"),
      screenSharing: screenSharing,
    });
  };

  const setCard = (card) => {
    const settings = setting.settings;
    settings.card = card;
    setSettings(settings);
    socket.emit("changeCard", { card: card, room: query.get("lessonID") });
  };

  const endLesson = () => {
    socket.emit("endLesson", { lessonID: query.get("lessonID") });
  };

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
  }, []);

  const divWhiteboard = useCallback(
    (node) => {
      if (node !== null) {
        setHeight(node.getBoundingClientRect().height);
        setWidth(node.getBoundingClientRect().width);
      }
    },
    [dimensions]
  );

  if (isEmpty(props.lesson?.lesson)) {
    return (
      <div>
        <Navbar view={view} setView={setView} />
        {loading ? <Spinner /> : <NotFound />}
      </div>
    );
  }
  return (
    <div
      className={!screenSharing && "class"}
      style={configuration !== 2 ? { background: "#6399AE" } : {}}
    >
      <Navbar view={view} setView={setView} endLesson={endLesson} />

      {configuration > 1 ? (
        <div className={`class__${view}__container`}>
          {window.innerWidth > 900 && (
            <div
              className={`class__${view}__video`}
              style={screenSharing ? { height: 0 } : {}}
            >
              <Video
                query={query}
                view={view}
                setView={setView}
                setCard={setCard}
                ref={childRef}
                setScreenSharing={setScreenSharing}
              />
            </div>
          )}

          <div className={`class__${view}__content`} ref={divWhiteboard}>
            {width > 0 && height > 0 && (
              <TabsMenu
                height={height}
                width={width}
                query={query}
                screenSharing={
                  <div className="video__screenSharing--container">
                    <div
                      id="screenPublisherContainer"
                      style={{ width: "auto", height: 100 }}
                    />
                    <div
                      id="screenSubscriberContainer"
                      style={{ width: "auto", height: 100 }}
                    />
                  </div>
                }
                card={card}
                setCard={setCard}
                lesson={lesson}
              />
            )}
          </div>
        </div>
      ) : configuration === 1 ? (
        <Popup2
          open={configuration === 1}
          nextStep={setConfiguration}
          onClose={() => console.log("")}
        />
      ) : (
        <Popup1
          open={configuration === 0}
          nextStep={setConfiguration}
          onClose={() => console.log("")}
        />
      )}
      {props.flash?.map((flash) => {
        return (
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={() => deleteFlashMessage(flash.id)}
          >
            <Alert
              onClose={() => deleteFlashMessage(flash.id)}
              severity={flash.type}
            >
              {flash.text}
            </Alert>
          </Snackbar>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.data,
  setting: state.setting,
  lesson: state.lesson,
  message: state.message,
  flash: state.flash,
});

const mapDispatchToProps = (dispatch) => ({
  setData: (data) => dispatch(setData(data)),
  setSettings: (data) => dispatch(setSettings(data)),
  setLesson: (data) => dispatch(getLesson(data)),
  setMessages: (data) => dispatch(setMessages(data)),
  initMessages: (data) => dispatch(initMessages(data)),
  addFlashMessage: (data) => dispatch(addFlashMessage(data)),
  deleteFlashMessage: (data) => dispatch(deleteFlashMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Class);
