import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MaterialAdd from "./MaterialAdd";
import MaterialAttachmentIcon from "@material-ui/icons/AttachFile";
import "./docs.styles.scss";
import MaterialItem from "./MaterialItem";
import Pdf from "../../assets/pdf.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./materials.styles.scss";
import PdfViewer from "./PdfViewer";

const Materials = (props) => {
  useEffect(() => {
    console.log(props.lesson?.lesson?.materials);
  }, []);
  const [idPdf, setIdPdf] = useState("");
  return (
    <div className="materials">
      <div className="materials__container">
        {idPdf ? (
          <PdfViewer url={idPdf} closePdf={setIdPdf} />
        ) : (
          <div>
            <MaterialAdd />
            <div className="materials__container--list">
              <div className="materials__container--list--title">
                Your files:
              </div>
              {props.lesson?.lesson?.materials?.map((material) => {
                if (material.type !== "Google") {
                  if (material.type === "Pdf") {
                    return (
                      <MaterialItem
                        img={Pdf}
                        material={material}
                        showPdf={setIdPdf}
                      />
                    );
                  } else {
                    return (
                      <MaterialItem
                        img={material.img}
                        material={material}
                        showPdf={setIdPdf}
                      />
                    );
                  }
                }
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lesson: state.lesson,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Materials);
