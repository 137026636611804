import { createMuiTheme } from "@material-ui/core/styles";
import typography from "./typography";

// colors variables
const blue1 = "#272d4f";
const blue2 = "#686C84";
const blue3 = "#A8ABB8";
const gold = "#CBAC63";
const gold2 = "#F5EDDE";
const green = "#BAC7BB";
const green2 = "#E2EAE3";
const orange = "#FF6C00";
const brown = "#ca8861";

const theme = createMuiTheme({
  palette: {
    common: {
      green: brown,
      gold: brown,
      blue: blue1,
      blue2: blue2,
      brown: brown,
      brownlight: "#ecdfd8",
      red: orange,
    },
    primary: {
      main: blue1,
    },
    secondary: {
      main: gold,
    },
  },
  typography,
});

export default theme;
