import io from "socket.io-client";

let socket = io.connect("https://socket-server-messenger.herokuapp.com/", {
  transports: ["websocket"],
});

// let socket = io.connect("localhost:7000", {
//   transports: ["websocket"],
// });

export default socket;
