import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./arguments.styles.scss";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
const useStyles = makeStyles({
  root: { width: "100%", boxShadow: "0 0 0", border: "1px solid lightgray" },
  content: {},
  title: {
    fontSize: 22,
  },
  item: {
    width: "100%",
  },
});

const Arguments = () => {
  const classes = useStyles();
  return (
    <div className="arguments">
      <div className="arguments__container">
        <div className="arguments__item">
          <h3>Big tech should be broken up</h3>
        </div>
        <div className="arguments__pros">
          <div className="arguments__pros__item">
            <h2>Pros</h2>
          </div>
          <div className="arguments__pros__item__right">
            <h2>Cons</h2>
          </div>
        </div>
        <div className="arguments__row">
          <div className="arguments__column" style={{ paddingRight: "4px" }}>
            <div className="arguments__element">
              <p>Big Tech infringes on our privacy</p>
            </div>
          </div>
          <div className="arguments__column" style={{ paddingLeft: "4px" }}>
            <div className="arguments__element">
              <p>Big Tech infringes on our privacy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Arguments;
