import { Button } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
// import { getArticle } from "src/actions/actions-teacher/articleActions";
// import ShareIcon from "src/assets/share.png";
// import Page from "src/components/Page";

// import LinearProgress from "../../LinearProgess";
import MainPoints from "../../MainPoints";
import SoundSmart from "../../SoundSmart";
// import Teachers from "../../Teachers";
// import Voting from "../../Voting";
// import Kialo from "./circle";

const BlogPost = (props) => {
  const [height, setHeight] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [slug, setSlug] = useState("cyber-war");
  const [positions, setPositions] = useState([]);
  const arg1 = useRef(null);
  const arg2 = useRef(null);
  const arg3 = useRef(null);
  const arg4 = useRef(null);
  const arg5 = useRef(null);
  const arg6 = useRef(null);

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", listenScrollEvent);

    props.getArticle("cyber-war");

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  useEffect(() => {
    setProfiles(props.article?.article?.profiles);
    const postionsArr = [
      arg1.current.offsetTop - 200,
      arg2.current.offsetTop - 200,
      arg3.current.offsetTop - 200,
      arg4.current.offsetTop - 200,
      arg5.current.offsetTop - 200,
      arg6.current.offsetTop - 200,
    ];
    setPositions(postionsArr);
    console.log(postionsArr);
  }, [props.article?.article]);

  const listenScrollEvent = () => {
    setHeight(window.scrollY);
  };

  return (
    // <Page
    //   title="Should the US retaliate with cyberwafare against the Russian attacks?"
    //   description="The Pentagon, intelligence agencies, nuclear labs and Fortune 500 companies use software that was found to have been compromised by Russian hackers. The sweep of stolen data is still being assessed. About 18,000 private and government users downloaded a Russian tainted software update - a Trojan horse of sorts - that gave it hackers a foothold into victims’ systems, according to SolarWinds, the company whose software was compromised. ”"
    // >
    <div className="blog">
      {/* <LinearProgress height={height} maxHeight={positions[5]} /> */}
      <div className="blog__container">
        <div className="blog__content">
          <h6>Security</h6>
          <h1>
            Should the US retaliate with cyberwafare against the Russian
            attacks?
          </h1>
          <h3 style={{ position: "relative" }}>
            The Pentagon, intelligence agencies, nuclear labs and Fortune 500
            companies use software that was found to have been compromised by
            Russian hackers. The sweep of stolen data is still being assessed.
            About 18,000 private and government users downloaded a Russian
            tainted software update - a Trojan horse of sorts - that gave it
            hackers a foothold into victims’ systems, according to SolarWinds,
            the company whose software was compromised.
          </h3>
          <MainPoints
            arguments={[
              "Russia has consistently used their cyberwarfare capacities to attack the US, significantly disrupting their economic and political life.",
              "One of the specific targets were Big Tech companies crucial for the US economy.",
              "The election of Joe Biden might change the character of the US-Russia relationship.",
            ]}
          />

          <div className="blog__content__buttons">
            <div>
              <h6>April 08, 2021</h6>
              <p>{props.article?.article?.time} minutes read</p>
            </div>
            {/* <Button
                startIcon={<TodayIcon />}
                color="secondary"
                variant="outlined"
                component={RouterLink}
                to={`/student/results/?slug=${slug}`}
              >
                Book a lesson
              </Button> */}
            {/* <Button
                startIcon={
                  <img
                    src={ShareIcon}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              >
                Share
              </Button> */}
          </div>

          <p className="paragraph">
            Recently, White House sources confirmed that President Joe Biden has
            authorized retaliation against the Russian government for the recent
            hacking attributed to Russia. No word of when nor how, but it’s said
            to be “devastating”. Should the US retaliate with cyberwafare
            against the Russian attacks?
          </p>
          <SoundSmart
            arguments={[
              "American cyber attacks might cause a ban on all Russian companies preventing international transfers of money.",
              "One of the companies hit by the Russian attacks was Microsoft.",
              "Cyber warfare might be a defensive measure but it has massive implications for the public image of specific politicians.",
            ]}
          />
          <h2>FOR</h2>
          <h3 style={{ position: "relative" }} ref={arg1}>
            Russia will be deterred from further attacks
          </h3>
          <p className="paragraph">
            It is rather clear that the overarching goal of any retaliatory
            action, which could also include stepped-up counter cyber espionage
            efforts, would be to create an effective deterrence and diminish the
            potency of future Russian cyber spying. The reasoning as to why this
            might be an effective deterrence is two-fold.
          </p>
          <p className="paragraph-2">
            Firstly, Putin has got away with murder, literally and figuratively,
            while Trump looked the other way. Russia was given almost a free
            hand in their detrimental activities on the domestic and
            international stage. Self-respect and public expectations demand
            Biden hit back hard. It would establish a new set of rules in the
            relationship with Russia whereby the US is not seen as a country
            that will constantly ignore all Russian actions even if they are
            clearly not aligned with the American security interests.
          </p>
          {/* <blockquote className="groucho">
              Only 12% want to return to full-time office work, and 72% want a
              hybrid remote-office model moving forward
              <footer>ŹRODLO</footer>
            </blockquote> */}
          <p className="paragraph-2">
            Secondly, the retaliation might be quite potent. “Symbolic won’t do
            it” for any US response, said James Andrew Lewis, a cyber security
            expert at the Center for Strategic and International Studies, a
            Washington think tank. “You want the Russians to know we’re pushing
            back”. Lewis said a stronger option could be to cut Russia off from
            the SWIFT international bank transfer and financial messaging
            system, a crippling move that would prevent Russian companies from
            processing payments to and from foreign customers. Given the Russian
            economy is already shattered and even more so because of the
            pandemic, this might be a successful deterrence.
          </p>

          {/* <Teachers slug={slug} /> */}

          <h3 style={{ position: "relative" }} ref={arg2}>
            It might help Biden’s image
          </h3>
          <p className="paragraph">
            The responses will start to define how President Biden fashions his
            new administration’s response to escalating cyberconflict and
            whether it can find a way to impose a steeper penalty on rivals who
            regularly exploit vulnerabilities in government and corporate
            defences to spy, steal information and potentially damage critical
            components of the nation’s infrastructure.
          </p>
          <p className="paragraph-2">
            The salience of Biden’s image goes well beyond his own electability.
            On the one hand, the US is polarised as never before and needs
            something that would unify the nation. Weaponizing the wide-spread
            fear of Russia might be that thing. On the other hand, one of the
            major challenges for the new administration is fostering a
            successful relationship with some of the most powerful companies in
            general and Big Tech companies in particular. Demonstrating that the
            government is willing to step in and defend them - after all, one of
            the companies hit by the Russian attack was Microsoft - would
            clearly increase buy-in to the administration.
          </p>

          <p className="paragraph-2">
            This is even more important given the uneasy past of Biden. In 2009,
            he was an advocate of Barack Obama’s failed Russia ‘reset’. In his
            whole political career spanning over a few decades, there was no
            single situation when Biden presented himself as particularly tough
            on Russia. Retaliating now might change it.
          </p>

          <h3 style={{ position: "relative" }} ref={arg3}>
            The US will show they care about their allies
          </h3>
          <p className="paragraph">
            Equally, the response could be an early test of the president’s
            promise to cooperate and consult more effectively with US allies.
            This is a multi-faceted problem. While some countries are
            particularly afraid of Russia and await the harder response, others
            suffer from the strained Sino-American relationship trying to
            balance between the two countries. This is particularly visible with
            the countries in the Pacific area. The Philippines or South Korea -
            long-standing allies of the United States - are constantly
            threatened by China’s navy aggressive maneuvers in the immediate
            vicinity of their lands. For them, the American’s answer to Russian
            attacks does not matter. In either case, showing a strong signal
            that the US wants to be on the same side might be beneficial for
            increasing trust from various American allies.
          </p>
          <p className="paragraph-2">
            This perspective is crucially important when considering the
            ‘special relationship’ with the United Kingdom. The lawless realms
            of rapidly evolving global cyber-warfare technology were identified
            as a major concern in the British defence and security review. Not
            only the United Kingdom is one of the most important proponents of a
            harsher stance on Russia but it also plans on massively investing in
            their own cybersecurity forces. If the Biden administration is to
            forge better ties with Boris Johnson - sympathetic of Trump - it
            might be one of the last chances to do so.
          </p>

          <h2>AGAINST</h2>
          <h3 style={{ position: "relative" }} ref={arg4}>
            This will only escalate the conflict with Russia
          </h3>
          <p className="paragraph">
            Since the Revolution of Dignity in Ukraine and the annexation of
            Crimea that followed it, Russia was heavily sanctioned by a number
            of various actors. This did not result in anything but backlash -
            Russians poisoned Sergei Skripal on the British soil and their own
            most important opposition leader Alexey Navalny. Some of the imposed
            retaliatory measures were actually quite personal in nature and
            restricted freedoms of prominent Russian officials. By now, it is
            clear that none of that has worked. Unless the US is interested in
            engaging in an extremely disruptive cyber attack - and there is no
            evidence for that - it is counter-intuitive to expect Russia will
            react differently this time.
          </p>

          <h3 style={{ position: "relative" }} ref={arg5}>
            Biden will lose on it
          </h3>
          <p className="paragraph">
            While the exact shape of the US retaliation is unclear and
            additional measures such as economic sanctions are to be expected,
            officials say the main US effort to punish Putin will comprise
            potentially devastating, semi-clandestine cyber-attacks targeting
            Russia’s intelligence agencies and government networks. Even in the
            best case scenario where it deters Russia, it does not answer to the
            second harmful consequence of the Russian attacks - the decrease of
            trust put in the American government. It is difficult to believe
            that a few, even detrimental, attacks on Russian facilities will
            make companies like Microsoft forget the losses they suffered due to
            the security breaches. Moreover, what should not be underestimated
            is the public opinion’s role. If an average Joe does not even see
            this retaliation, it will likely not repair the American image.
          </p>

          <h3 style={{ position: "relative" }} ref={arg6}>
            It might strain the US relationship with Germany
          </h3>
          <p className="paragraph">
            The US security interests are not congruent with those of all of its
            allies. Lately, the US again demanded that Germany halt work on the
            Nord Stream 2 Baltic pipeline project connecting them with Russia.
            Throughout the last few years, Germany retained their rather warm
            relationship with Russia in spite of the US occasional pressure.
            There is little evidence suggesting that Germany would be interested
            in sacrificing their economic ties for the tokens of gratitude
            coming from Americans. Equally, it is unclear whether the US has
            enough might to influence Germany. The coolness between the two
            countries permeates almost all elements of their bilateral relations
            and is particularly visible in regards to NATO. The continuous calls
            from Donald Trump to make European countries contribute more to the
            NATO’s budget ended up in Germany bringing back the idea of a
            European Army.
          </p>

          {/* <Voting
              text={
                "Note that cyber warfare is not the only means of retaliation and the outcome of the US actions is clearly premised on the shape of these means! Would any other type of retaliation yield better return?"
              }
            /> */}
        </div>
        {/* {window.innerWidth > 1180 && (
            <Kialo
              height={height}
              slug={slug}
              positions={!isEmpty(positions) ? positions : []}
            />
          )} */}
      </div>
    </div>
    // </Page>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  article: state.article,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(BlogPost);
