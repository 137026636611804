import React from "react";
import ThumbDown from "@material-ui/icons/ThumbDownRounded";
import ThumbUp from "@material-ui/icons/ThumbUpRounded";
import ArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Arguments from "./kialo/Arguments";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: theme.palette.common.blue,
  },
}));

const Voting = (props) => {
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  return (
    <div
      className="voting"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h2>THINK CRITICALLY! </h2>
      <h3>{props.text}</h3>
    </div>
  );
};

export default Voting;
