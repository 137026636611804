const INIT_STATE = {
  lesson: {},
};

const lessonReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_LESSON":
      return {
        ...state,
        lesson: action.payload,
      };
    default:
      return state;
  }
};

export default lessonReducer;
