import React from "react";

const MainPoints = (props) => {
  return (
    <div className="blog__points">
      <p>What is this piece about?</p>
      <ul className="blog__points__list">
        {props?.arguments.map((argument) => (
          <li className="blog__points__item">{argument}</li>
        ))}
      </ul>
    </div>
  );
};

export default MainPoints;
