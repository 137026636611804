const INIT_STATE = {
  messages: [],
};

const messageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_MESSAGES":
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case "INIT_MESSAGES":
      return {
        ...state,
        messages: action.payload,
      };
    default:
      return state;
  }
};

export default messageReducer;
