import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import {
  Link,
  useLocation,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { createBrowserHistory } from "history";

import "./App.css";
import Class from "./containers/Class";
import Feedback from "./containers/Feedback";

const history = createBrowserHistory();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <div className="App">
          <Route path="/" component={Class} exact />
          <Route path="/feedback" component={Feedback} exact />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
