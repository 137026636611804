import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
const styles = {
  button: {
    margin: 5,
    marginTop: 15,
    width: "100%"
  },
  input: {
    display: "none"
  },
  rightIcon: {
    marginLeft: 5
  }
};

function Upload(props) {
  const { classes, onChange, name } = props;
  return (
    <div>
      <input
        name={name}
        accept="application/pdf"
        className={classes.input}
        id="outlined-button-file"
        multiple
        onChange={onChange}
        type="file"
      />
      <label htmlFor="outlined-button-file">
        <Button variant="outlined" component="span" className={classes.button}>
          Upload file
          <CloudUploadIcon className={classes.rightIcon} />
        </Button>
      </label>
    </div>
  );
}

Upload.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Upload);
