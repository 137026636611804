import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  ButtonGroup,
  Button,
  Menu,
  MenuItem,
  Divider,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core/";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Link, useLocation } from "react-router-dom";
import Badge from "@material-ui/core/Badge";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { connect } from "react-redux";
import Logo from "../../assets/nativated.png";

const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  navbar: {
    backgroundColor: "#fff",
    boxShadow: "0 0 5px lightgray",
  },

  toolbar: {
    padding: "0 30px",
  },
  logo: {
    height: 52,
    [theme.breakpoints.down("md")]: {
      height: 46,
    },
  },
  logoContainer: {
    padding: 0,
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  tabContainer: {
    marginLeft: "auto",
    padding: 0,
  },
  tab: {
    ...theme.typography.tab,
    minWidth: 12,
    marginLeft: "25px",
    height: 80,
    color: theme.palette.common.blue,
    borderBottom: "2px solid white",
    borderTop: "2px solid white",
    "&:hover": {
      backgroundColor: "white",
      borderBottom: `2px solid ${theme.palette.common.green}`,
    },
  },
  buttonStatus: {
    marginLeft: "15px",
  },
  buttonGroup: {},
}));

const Navbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = props.data;
  const { lesson } = props.lesson;

  return (
    <React.Fragment>
      <AppBar position="fixed" className={clsx(classes.navbar)} elevation={0}>
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <img src={Logo} className={classes.logo} />
          <div style={{ marginLeft: "auto" }}>
            {data.role === "teacher" && window.innerWidth > 900 && (
              <ButtonGroup color="primary" className={classes.buttonGroup}>
                <Button onClick={() => props.setView(0)}>Class Tools</Button>
                <Button onClick={() => props.setView(1)}>Conversation</Button>
                {/* <Button onClick={() => props.setView(2)}></Button> */}
              </ButtonGroup>
            )}

            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              onClick={handleClick}
              className={classes.buttonStatus}
              color="primary"
              style={
                lesson.started && !lesson?.finished
                  ? { borderColor: "green", color: "green" }
                  : {}
              }
            >
              {!lesson?.started
                ? "Not Started"
                : !lesson?.finished
                ? "In  progress"
                : "Finished"}
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbarMargin} />
      {data.role === "teacher" && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Start class</MenuItem>
          <MenuItem
            onClick={() => {
              props.endLesson();
              handleClose();
            }}
          >
            End Class
          </MenuItem>
          <MenuItem onClick={handleClose}>Leave class</MenuItem>
        </Menu>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  data: state.data,
  lesson: state.lesson,
  setting: state.setting,
});

export default connect(mapStateToProps, {})(Navbar);
