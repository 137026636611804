import shortid from "shortid";
import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from "../types";

export const addFlashMessage = (message, delay = 6000) => (dispatch) => {
  let shortId = shortid.generate();
  setTimeout(() => dispatch(deleteFlashMessage(shortId)), delay);
  dispatch({
    type: ADD_FLASH_MESSAGE,
    message,
    shortId,
  });
};

export const deleteFlashMessage = (id) => {
  return {
    type: DELETE_FLASH_MESSAGE,
    id,
  };
};
