import React, { useState } from "react";
import SmsIcon from "@material-ui/icons/Sms";
import {IconButton} from "@material-ui/core";
import MessengerVideo2 from "./MessengerVideo2";


const PopIcon = (props) => {
  const [view, setView] = useState(0);

  const openChat = () => {
      setView(1);
  };
  const closeChat  = () => {
      setView(0);
  }
  return (
    <div>
      {view === 0 ? (
        <div className={"popicon"}>
            <IconButton onClick={openChat}>
          <SmsIcon
            style={{
              width: "100%",
              height: "100%",
              color: " rgba(240, 240, 240, 1)",
            }}
          />
          </IconButton>
        </div>
      ) : view === 1 ? (
       <MessengerVideo2 closeChat={closeChat}/>
      ) : (
          <div/>
      )}
    </div>
  );
};
export default PopIcon;
