import React from "react";

import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Link,
} from "@material-ui/core";
import Image404 from "../../assets/undraw_page_not_found_su7k.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: "100%",
    width: 560,
    maxHeight: 300,
    height: "auto",
  },
}));

const NotFoundView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="lg" style={{ marginTop: "5rem" }}>
      <Typography
        align="center"
        variant={mobileDevice ? "h4" : "h1"}
        color="textPrimary"
      >
        404: The lesson with this id doesn't exist.
      </Typography>
      <Typography align="center" variant="subtitle2" color="textSecondary">
        You either tried some shady route or you came here by mistake. Whichever
        it is, use the button below to come back to nativated page.
      </Typography>
      <Box mt={6} display="flex" justifyContent="center">
        <img alt="404" className={classes.image} src={Image404} />
      </Box>
      <Box mt={6} display="flex" justifyContent="center">
        <Button
          color="secondary"
          component={Link}
          href="https://www.app.nativated.com/"
          variant="outlined"
        >
          Back to Nativated
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundView;
