import React from "react";

const MainPoints = (props) => {
  return (
    <div className="blog__points" style={{ backgroundColor: "#BAC7BB" }}>
      <p>Read it and you will sound smart!</p>
      <p>If you read the piece, you will learn that:</p>
      <ul className="blog__points__list">
        {props?.arguments.map((argument) => (
          <li className="blog__points__item">{argument}</li>
        ))}
      </ul>
    </div>
  );
};

export default MainPoints;
