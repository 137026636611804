import React, { useState, useEffect } from "react";
import axios from "axios";
import Upload from "../../components/inputs/Upload";
import { Button, Card, CardContent } from "@material-ui/core";
import MultiAutoComplete from "../../components/inputs/MultiAutoComplete";
import TextFieldDetail from "../../components/inputs/TextFieldDetail";
import isEmpty from "../../validation/is-empty";
import Autocomplete from "@material-ui/core";
import MaterialItem from "./MaterialItem";
import { connect } from "react-redux";
import socket from "../../socket";
import { addFlashMessage } from "../../redux/flash/flash.actions";
import Spinner from "../../components/spinner/Spinner";
import MaterialPreview from "./MaterialPreview";

const MaterialAdd = (props) => {
  const [file, setFile] = useState();
  const [type, setType] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [linkData, setLinkData] = useState();

  const { addFlashMessage } = props;

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const getUrlTags = async () => {
    setLoading(true);

    let response = await axios
      .post("/api/v1/materials/link", {
        url: url,
      })
      .then(async (res) => {
        if (res.data) {
          console.log(res.data);
          await setLinkData(res.data);
          await setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const addMaterial = async () => {
    setLoading(true);
    const data = {};
    data.title = title;
    data.description = description;
    data.lessonID = props?.data?.data?.lessonID;

    if (file) {
      const uploadConfig = await axios.post("/api/v1/aws", {
        userID: props?.data?.data?.userID,
      });
      await axios.put(uploadConfig.data.url, file, {
        transformRequest: [
          (data, headers) => {
            delete headers.common.Authorization;
            return data;
          },
        ],
        headers: {
          "Content-Type": file.type,
        },
      });

      await axios
        .post(`/api/v1/materials/create/${props?.data?.data.lessonID}`, {
          data,
          url: uploadConfig.data.url,
          title: file?.name,
          type: type.value,
        })
        .then((res) => {
          socket.emit("updateLesson", props?.data?.data);
          setFile(null);
          setTitle(null);
          setUrl(null);
          setType(null);
          addFlashMessage({
            text: `File uploaded.`,
            type: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post(`/api/v1/materials/create/${props?.data?.data.lessonID}`, {
          data,
          type: type.value,
          linkData,
        })
        .then((res) => {
          socket.emit("updateLesson", props?.data?.data);
          setTitle(null);
          setUrl(null);
          setLinkData(null);
          addFlashMessage({
            text: `Material added.`,
            type: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="materials__container--add">
      <div className="materials__container--add--container">
        <Card>
          <CardContent>
            <MultiAutoComplete
              label="Type of material"
              placeholder="Choose type of material"
              name="type"
              key="type"
              suggestions={types}
              onChange={(e, value) => {
                setType(value);
              }}
              getOptionSelected={(option, value) => {
                return option.value === value.value;
              }}
              value={type}
              // value={subjects}
              multi={false}
            />
            {type?.label === "Pdf" ? (
              <React.Fragment>
                <Upload
                  onChange={(e) => onFileChange(e)}
                  name="file"
                  accept={"application/pdf"}
                  value={file}
                />
                <p>{file?.name}</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TextFieldDetail
                  placeholder="Url of a material"
                  label="URL"
                  onChange={(e) => setUrl(e.target.value)}
                  value={url}
                  onBlur={() => getUrlTags()}
                />
                {!loading && !isEmpty(linkData) && (
                  <MaterialPreview img={linkData.img} material={linkData} />
                )}
              </React.Fragment>
            )}
            {loading && <Spinner />}
            <div className="materials__buttoncontainer">
              <Button
                variant="contained"
                color="primary"
                onClick={addMaterial}
                disabled={loading}
              >
                Add material
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const types = [
  { label: "Link", value: "Link", index: 0 },
  { label: "Video", value: "Video", index: 1 },
  { label: "Pdf", value: "Pdf", index: 2 },
];
const mapStateToProps = (state) => ({
  lesson: state.lesson,
  data: state.data,
});

const mapDispatchToProps = (dispatch) => ({
  addFlashMessage: (data) => dispatch(addFlashMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialAdd);
