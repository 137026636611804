/* eslint-disable no-use-before-define */
import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function Tags(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple={props.multi}
        id={props.name}
        options={props.suggestions}
        getOptionLabel={(option) => option.label}
        onChange={props.onChange}
        value={props.value}
        limitTags={props.limit}
        getOptionSelected={props.getOptionSelected}
        // defaultValue={[top100Films[13]]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            margin="normal"
            label={props.label}
            fullWidth
            placeholder={props.placeholder}
          />
        )}
      />
    </div>
  );
}
