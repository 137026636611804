import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Grid,
  RadioGroup,
  Radio,
  Link,
  FormControlLabel,
  Snackbar,
} from "@material-ui/core";
import React, { useState } from "react";
import "./feedback.styles.scss";
import Rating from "@material-ui/lab/Rating";
import Navbar from "./navbar/Navbar";
import axios from "axios";
import {
  Link as RouterLink,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import {
  addFlashMessage,
  deleteFlashMessage,
} from "../redux/flash/flash.actions";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Feedback = (props) => {
  const [value, setValue] = useState(0);
  const [lesson, setLesson] = useState(0);
  const [article, setArticle] = useState(0);
  const [teacher, setTeacher] = useState(0);
  const [con, setContinue] = useState();
  const [comments, setComments] = useState("");
  const [student, setStudent] = useState();
  const [classroom, setClassRoom] = useState();
  const [submited, setSubmited] = useState(false);

  let query = useQuery();

  const sendFeedback = () => {
    let role = query.get("role");
    let feedback;
    if (role === "student") {
      feedback = {
        lesson: lesson,
        article: article,
        teacher: teacher,
        continue: con,
        comments: comments,
      };
    } else {
      feedback = {
        lesson: lesson,
        classroom: classroom,
        student: student,
        comments: comments,
      };
    }

    axios
      .post("/api/v1/lessons/feedback", {
        feedback: feedback,
        lessonID: query.get("lessonID"),
        role: query.get("role"),
      })
      .then((res) => {
        if (res) {
          // addFlashMessage({
          //   text: `Thank you for submitting your feedback!`,
          //   type: "success",
          // });
          setSubmited(true);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <Navbar />

      <div className="feedback">
        {submited ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h2">
              Thank you for submitting your feedback!
            </Typography>

            <Button
              color="secondary"
              component={Link}
              href="https://www.newsclass.nativated.com/login"
              variant={"outlined"}
              size={"large"}
              style={{ marginTop: "3rem" }}
            >
              Go back to News Class!
            </Button>
          </div>
        ) : (
          <Card>
            <CardContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="body1">
                    {" "}
                    How did you enjoy the lesson?
                  </Typography>
                  <Rating
                    name="simple-controlled"
                    value={lesson}
                    style={{ marginTop: "0.5rem" }}
                    onChange={(event, newValue) => {
                      setLesson(newValue);
                    }}
                  />
                </Grid>

                <Grid item>
                  <Typography variant="body1">
                    {" "}
                    How did you like the article?
                  </Typography>
                  <Rating
                    name="simple-controlled2"
                    value={article}
                    style={{ marginTop: "0.5rem" }}
                    onChange={(event, newValue) => {
                      setArticle(newValue);
                    }}
                  />
                </Grid>
                {query.get("role") === "student" ? (
                  <React.Fragment>
                    <Grid item>
                      <Typography variant="body1">
                        {" "}
                        How did you like the teacher?
                      </Typography>
                      <Rating
                        name="simple-controlled3"
                        value={teacher}
                        style={{ marginTop: "0.5rem" }}
                        onChange={(event, newValue) => {
                          setTeacher(newValue);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        {" "}
                        Would you like to continue learning at News Class?
                      </Typography>
                      <RadioGroup
                        aria-label="continue"
                        name="continue1"
                        value={con}
                        onChange={(e) => setContinue(e.target.value)}
                      >
                        <FormControlLabel
                          value={"Yes"}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={"No"}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid item>
                      <Typography variant="body1">
                        {" "}
                        How good was the student’s English?
                      </Typography>
                      <Rating
                        name="simple-controlled6"
                        value={student}
                        style={{ marginTop: "0.5rem" }}
                        onChange={(event, newValue) => {
                          setStudent(newValue);
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Typography variant="body1">
                        {" "}
                        How intuitive did you find the online classroom?
                      </Typography>
                      <Rating
                        name="simple-controlled7"
                        value={classroom}
                        style={{ marginTop: "0.5rem" }}
                        onChange={(event, newValue) => {
                          setClassRoom(newValue);
                        }}
                      />
                    </Grid>
                  </React.Fragment>
                )}

                <Grid item>
                  <TextField
                    placeholder="Comments"
                    label="Comments"
                    fullWidth
                    onChange={(e) => setComments(e.target.value)}
                    value={comments}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => sendFeedback()}
                  >
                    Submit feedback
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        {props.flash?.map((flash) => {
          return (
            <Snackbar
              open={true}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              autoHideDuration={6000}
              onClose={() => deleteFlashMessage(flash.id)}
            >
              <Alert
                onClose={() => deleteFlashMessage(flash.id)}
                severity={flash.type}
              >
                {flash.text}
              </Alert>
            </Snackbar>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  flash: state.flash,
});

const mapDispatchToProps = (dispatch) => ({
  addFlashMessage: (data) => dispatch(addFlashMessage(data)),
  deleteFlashMessage: (data) => dispatch(deleteFlashMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
