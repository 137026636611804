import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

// Styling material-ui native element
const styles = {
  textField: {
    marginTop: 15,
    minWidth: "280px",
    width: "100%",
  },
  input: {
    color: "#333",
  },
};

const TextFieldDetail = ({
  name,
  placeholder,
  value,
  label,
  error,
  info,
  type,
  onChange,
  rows,
  disabled,
  password,
  onBlur,
  multiline,
  classes,
  minWidth,
}) => {
  return (
    <div>
      <TextField
        id="standard-full-width"
        name={name}
        label={label}
        placeholder={placeholder}
        rows={rows}
        className={classes.textField}
        value={value}
        error={error}
        style={{ minWidth: minWidth }}
        fullWidth
        onBlur={onBlur}
        onChange={onChange}
        margin="normal"
        disabled={disabled}
        multiline={multiline}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          className: classes.input,
        }}
      />
      {error && (
        <div style={{ fontSize: "0.8rem", color: "red", marginTop: "-8px" }}>
          {error}
        </div>
      )}
      {info && !error && (
        <p
          style={{ fontSize: "13px", color: "gray", lineHeight: "15px" }}
          className="textfieldmain__label-info"
        >
          {info}
        </p>
      )}
    </div>
  );
};

TextFieldDetail.propTypes = {
  // name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  // value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.string,
};
TextFieldDetail.defaultProps = {
  type: "text",
};

export default withStyles(styles)(TextFieldDetail);
