import React, { useEffect } from "react";
// import NProgress from "nprogress";
import { Box, LinearProgress, makeStyles } from "@material-ui/core";
import styles from "./loadingScreen.module.css";

const LoadingScreen = () => {
  return (
    <div className={styles.spinner}>
      <div className={styles.lds_ring}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
