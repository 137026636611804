import React, { useState, useEffect } from "react";
import { Button, Card, makeStyles } from "@material-ui/core";
import axios from "axios";
import socket from "../../socket";
import { addFlashMessage } from "../../redux/flash/flash.actions";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "rgba(27, 134, 144, 0.4)",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  buttonback: {
    backgroundColor: "#FF6C00",
    color: "white",
    marginTop: 10,
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
}));

const MaterialItem = (props) => {
  const { material, img, showPdf } = props;
  useEffect(() => {
    cutTitle();
  }, []);
  let title = material.title;
  const cutTitle = () => {
    if (material.title > 30) {
      return title.substring(0, 30);
    }
  };
  let classes = useStyles();
  const { addFlashMessage } = props;

  const deleteMaterial = (id) => {
    const data = {};
    data.materialID = id;
    console.log(props?.data?.data?.lessonID);
    axios
      .post(`/api/v1/materials/delete/${props?.data?.data?.lessonID}`, {
        data,
      })
      .then((res) => {
        socket.emit("updateLesson", props?.data?.data);
        addFlashMessage({
          text: `File deleted`,
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="materials__itemscontainer">
      <Card className="materials__item">
        <div className="materials__item--img--container">
          <img src={img} className="materials__item--img" />
        </div>
        <p className="materials__item--title">{title}</p>

        <div className="materials__item--func">
          {material.type === "Pdf" ? (
            <Button
              onClick={(e) => showPdf(material.url)}
              className={classes.button}
              variant="contained"
            >
              {" "}
              Show
            </Button>
          ) : (
            <Button
              onClick={(e) => window.open(material.url, "_blank")}
              className={classes.button}
              variant="contained"
            >
              {" "}
              Show
            </Button>
          )}

          <Button
            className={classes.buttonback}
            onClick={(e) => deleteMaterial(material._id)}
          >
            {" "}
            Delete
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lesson: state.lesson,
  data: state.data,
});

const mapDispatchToProps = (dispatch) => ({
  addFlashMessage: (data) => dispatch(addFlashMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialItem);
