import { Button } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
// import { getArticle } from "src/actions/actions-teacher/articleActions";
// import ShareIcon from "src/assets/share.png";
// import Page from "src/components/Page";

// import LinearProgress from "../../LinearProgess";
import MainPoints from "../../MainPoints";
import SoundSmart from "../../SoundSmart";
// import Teachers from "../../Teachers";
// import Voting from "../../Voting";
// import Kialo from "./circle";

const BlogPost = (props) => {
  const [height, setHeight] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [slug, setSlug] = useState("climate-change");
  const [positions, setPositions] = useState([]);
  const arg1 = useRef(null);
  const arg2 = useRef(null);
  const arg3 = useRef(null);
  const arg4 = useRef(null);

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", listenScrollEvent);

    // props.getArticle("climate-change");

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);
  useEffect(() => {
    setProfiles(props.article?.article?.profiles);
    const postionsArr = [
      arg1.current.offsetTop - 200,
      arg2.current.offsetTop - 200,
      arg3.current.offsetTop - 200,
      arg4.current.offsetTop - 200,
    ];
    setPositions(postionsArr);
  }, [props.article?.article]);

  const listenScrollEvent = () => {
    setHeight(window.scrollY);
  };

  return (
    <div className="blog">
      {/* <LinearProgress height={height} maxHeight={positions[3]} /> */}
      <div className="blog__container">
        <div className="blog__content">
          <h6>Ecology</h6>
          <h1>
            Finding the most effective way to combat climate change. Should we
            forget about plastic and focus only on fighting governments and
            corporations?
          </h1>
          <h3 style={{ position: "relative" }}>
            Already in 2018, the United Nations Intergovernmental Panel on
            Climate Change warned that the world stands on the brink of failure
            when it comes to controlling global warming and we have just a bit
            more than a decade to make a significant change. While in the last
            few years we have witnessed a lot of powerful social activism
            targeting climate change, not much has changed. Scientists are still
            warning us that the prospect of Earth becoming uninhabitable is very
            realistic. Therefore, it is crucial to find the most effective way
            to combat climate change. Should we forget about individual
            environmental ‘sins’ such as eating meat or using plastic and focus
            only on fighting governments and corporations?
          </h3>
          <MainPoints
            arguments={[
              "It is becoming widely-acknowledged that the climate change is a major, global issue even in circles that used to be skeptical",
              "there is an abundance of pro-environmental actions, however, scientists are still extremely pessimistic about the future ",
              "what is the most effective way to combat climate change?",
            ]}
          />

          <div className="blog__content__buttons">
            <div>
              <h6>April 20, 2021</h6>
              <p>{props.article?.article?.time} minutes read</p>
            </div>

            {/* <Button
                startIcon={
                  <img
                    src={ShareIcon}
                    style={{ width: "20px", height: "20px" }}
                  />
                }
              >
                Share
              </Button> */}
          </div>

          <SoundSmart
            arguments={[
              "71 percent of global emissions come from just 100 companies",
              "oceanic micro-organisms absorb four times more CO2 than the Amazon",
              "individual pro-environmental actions might be counterproductive in solving the climate change challenge",
            ]}
          />
          <h2>FOR</h2>
          <h3 style={{ position: "relative" }} ref={arg1}>
            The individual impact on climate change is miniscule
          </h3>
          <p className="paragraph">
            The comparison of individual and corporate/governmental impact on
            climate change is absolutely shocking. It is well-established that
            just a handful of major corporations from the fossil fuels industry
            might be seen as a major world pollutant.{" "}
            <span className="tooltip">
              According to the Carbon Majors Report from 2017, as much as 71
              percent of the world’s greenhouse gas emissions are generated by
              100 companies.
            </span>
            The report found that more than half of the global industrial
            emissions since 1988 – the year the Intergovernmental Panel on
            Climate Change was established – can be traced to just 25 corporate
            and state-owned entities. The scale of historical emissions
            associated with these fossil fuel producers is large enough to have
            contributed significantly to climate change, according to the
            report.
          </p>
          <p className="paragraph-2">
            ExxonMobil, Shell, BP and Chevron are identified among the highest
            emitting investor-owned companies since 1988. If fossil fuels
            continue to be extracted at the same rate over the next 28 years as
            they were between 1988 and 2017, says the report, average global
            temperatures will be on course to rise by 4C by the end of the
            century. This is likely to have catastrophic consequences including
            substantial species extinction and global food scarcity risks.
          </p>

          <p className="paragraph-2">
            Given all of the above, it might be claimed that the individual
            impact on climate change is really miniscule. Obviously, individuals
            buy products put into the market by a number of major polluting
            companies and, in this way, financially support their activity.
            However, in many cases consumer decisions cannot meaningfully exert
            any pressure on these companies.{" "}
            <span className="tooltip">
              Complicated, long supply-chains mean that these large polluting
              companies are likely to contribute (at some stage) to the
              production of the vast majority of products we see in our shops
              all around the world.
            </span>{" "}
            In other words, we might buy a recyclable paper bag that was stored
            in magazines supplied by fossil fuels and shipped from country to
            country by oil- or gas-fuelled transport.
          </p>

          {/* <Teachers slug={slug} /> */}

          <h3 style={{ position: "relative" }} ref={arg2}>
            Focusing on individuals make the real culprits less accountable
          </h3>
          <p className="paragraph">
            It might well be true that 71 percent of global emissions come from
            just 100 companies. But a sceptical person might argue that we still
            have a very tangible control over the remaining 29 percent of global
            emissions. However, the more we focus on individual action and
            neglect systemic change, the more we are just sweeping leaves on a
            windy day.
          </p>
          <p className="paragraph-2">
            We all know the dramatic consequences of climate change - rising sea
            levels, melting ice caps, dying animals or, as recently emphasised
            by the controversial Netflix film - Seaspiracy, the exodus of whole
            oceanic ecosystems. These are monumental impacts that cannot be
            ignored. But when we talk about the causes, we really go small.
            There are so many suggestions on “what you can do about climate
            change”. Do not use plastic cutlery. Go vegan. Buy reusable stuff.{" "}
            <span className="tooltip">
              If the answers are all in our hands and they seem to be ‘small
              changes’, then the blame cannot be anywhere but at our feet?{" "}
            </span>
            It means we all feel ashamed and run away from the topic of climate
            change if we cannot adjust to all of these ‘green expectations’.
            This terrible narrative leads to two tragic impacts.
          </p>

          <p className="paragraph-2">
            Firstly, this narrative is nothing but the victim blaming that makes
            the environmental movements inaccessible. If the price of admission
            to the climate movement is so high, as it requires you to eat more
            expensive food or stop flying, it leaves many people outside of the
            movement, reluctant to listen to climate activists, to vote
            differently and to take action against the real culprits -
            governments and corporations. The sad reality is that we can barely
            avoid doing some not-so-eco-friendly things because of the system we
            live in. For example, in the US more than 75% percent of energy if
            supplied by fossil fuels.
          </p>

          <p className="paragraph-2">
            Secondly, the emphasis on individual action is cannily weaponised by
            many industries. On the one hand, the fossil fuel industry
            frequently emphasises the progress that has been achieved through
            the use of their energy supplies.{" "}
            <span className="tooltip">
              When major oil and gas companies were sued in California, they
              explicitly argued that as long as consumers use their products and
              benefit from the development supplied by their activity, it would
              be unfair to punish them.
            </span>{" "}
            On the other hand, many industries’ greenwashing strategies are
            effective only because people are so obsessed with individual
            action. As it was greatly illustrated in Seaspiracy, it becomes very
            profitable to sell ‘eco-friendly’ fish. But, in many cases, what is
            marketed as good for the environment is, in fact, terrible.
          </p>

          <h2>AGAINST</h2>
          <h3 style={{ position: "relative" }} ref={arg3}>
            The most effective way to influence big players are consumer
            decisions
          </h3>
          <p className="paragraph">
            In some cases, there is really no good, pro-environmental choice.
            Some places are incredibly difficult to live in without a car and an
            electric one is very expensive. Not everyone lives in a country
            where supplying your home with solar, electric or wind energy is
            possible. We also have no influence over what sort of energy is used
            to run places we frequent or public transportation we use. But there
            is a choice in how we eat and it is well-established that the global
            food industry has a significant impact on climate change. This year,
            after a record number of people signed up for Veganuary, James
            Poole, a researcher at the University of Oxford, estimated the
            environmental impact of the month-long charity campaign, “for the
            350,000 people expected to take part in 2020,” he said. “this would
            save as much greenhouse gas emissions as moving 160,000 cars from
            the road, or about 400,000 to 500,000 single flights from London to
            Berlin”.
          </p>
          <p className="paragraph-2">
            <span className="tooltip">
              {" "}
              Today, the UN says meat and dairy (farmed livestock) accounts for
              14.5% of all man-made greenhouse gas emissions.
            </span>{" "}
            Going vegan does not always mean that all food we eat is entirely
            eco-friendly but it is clearly much better than sticking to a
            meat-based diet. The controversial film ‘Seaspiracy’ argued that
            resigning from eating fish might also have a paramount environmental
            impact. Exploitative fishing industry massively contributes to the
            depletion of the ocean life and there is very little accountability
            in terms of how fishing boats operate in the open sea - far away
            from any controlling, impartial human eye. The film’s claim that{" "}
            <span className="tooltip">
              oceanic micro-organisms absorb four times more CO2 than the Amazon
            </span>{" "}
            has been backed by the BBC’s verification.
          </p>

          <p className="paragraph-2">
            Cutting down on meat and fish might help the environment and exert
            real pressure to find better, more eco-friendly ways of producing
            food. Equally, the more people decide to go vegan, the cheaper and
            more available this option becomes to others - making it more likely
            to snowball.
          </p>
          <h3 style={{ position: "relative" }} ref={arg4}>
            The individual action increases the necessary awareness of climate
            change
          </h3>
          <p className="paragraph">
            Insofar as we might agree that nothing will be changed without
            corporations and governments halting their emissions, the question
            is how to influence them. Radical, direct action in the form of
            protests, manifestations, boycotts or even eco-terorrism is likely
            to work only if it is disruptive enough. The recent years have
            proven this to be an unlikely scenario. Even relatively mild
            disruptions such as blocking the underground in London by Extinction
            Rebellion has been heavily criticized by lots of average citizens.{" "}
            <span className="tooltip">
              Many people are not willing to pay the price of disruptions when
              it makes their jobs - necessary to provide life-sustaining income
              - harder.
            </span>
          </p>
          <p className="paragraph-2">
            However, each and everyone of us has other powerful tools in our
            hands - voting and consumer decisions. It is unlikely that the New
            Green Deal would have been even discussed in the US Congress if not
            for the electoral support for people like Bernie Sanders or
            Alexandra Ocassio-Cortez fighting for environmental justice. Maybe
            the promotion of reusable bags or going vegan in and of itself is
            not a particularly useful tool of combating climate change but it
            brings the necessary awareness that is going to influence the voting
            and consumer decisions. It is difficult to imagine a meaningful
            systemic change without the popular backing. All the ways of making
            people closer to the environmental cause surely contribute to the
            increase in the popular support for structural changes.
          </p>
          {/* 
            <Voting
              text={
                "Note that almost all new post-pandemic elements of the world of work are dependent on human decisions rather than objective factors. Think what decisions are most likely to be taken!"
              }
            /> */}
        </div>
        {/* {window.innerWidth > 1180 && (
            <Kialo
              height={height}
              slug={slug}
              positions={!isEmpty(positions) ? positions : []}
            />
          )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  article: state.article,
  errors: state.errors,
});

export default connect(mapStateToProps, {})(BlogPost);
