// import React from "react";
// import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";

/* Let CRA handle linting for sample app */
import React, { Component } from "react";
import Spinner from "react-spinner";
import classNames from "classnames";

import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";

import "./video.styles.scss";
import axios from "axios";
import isEmpty from "../../validation/is-empty";
import Messenger from "../messenger/Messenger";
import PopIcon from "./PopIcon";
import { Tab, Tabs } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    height: "100%",
  },
  tabs: {
    backgroundColor: "white",
    borderBottom: "1px solid lightgray",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  tab: {
    color: theme.palette.primary,
  },
  tabPanel: {
    width: "100%",
    padding: 0,
    margin: 0,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
});

let otCore;
const otCoreOptions = {
  credentials: {
    apiKey: "",
    sessionId: "",
    token: "",
  },
  // A container can either be a query selector or an HTML Element
  streamContainers(pubSub, type, data, stream) {
    return {
      publisher: {
        camera: "#cameraPublisherContainer",
        screen: "#screenPublisherContainer",
      },
      subscriber: {
        camera: "#cameraSubscriberContainer",
        screen: "#screenSubscriberContainer",
      },
    }[pubSub][type];
  },
  controlsContainer: "#controls",
  packages: ["screenSharing", "annotation"],
  communication: {
    callProperties: null, // Using default
  },
  screenSharing: {
    extensionID: "plocfffmbcclpdifaikiikgplfnepkpo",
    annotation: true,
    externalWindow: false,
    dev: true,
    screenProperties: {
      insertMode: "append",
      width: "100%",
      height: "100%",
      showControls: true,
      style: {
        buttonDisplayMode: "off",
      },
      videoSource: "window",
      fitMode: "contain", // Using default
    },
  },
  annotation: {
    absoluteParent: {
      publisher: ".Video-video-container__1",
      subscriber: ".Video-video-container__1",
    },
  },
};

/**
 * Build classes for container elements based on state
 * @param {Object} state
 */
const containerClasses = (state) => {
  const { active, meta, localAudioEnabled, localVideoEnabled } = state;
  const sharingScreen = meta ? !!meta.publisher.screen : false;
  const viewingSharedScreen = meta ? meta.subscriber.screen : false;
  const activeCameraSubscribers = meta ? meta.subscriber.camera : 0;
  const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
  const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
  const screenshareActive = viewingSharedScreen || sharingScreen;
  return {
    controlClass: classNames("Video-control-container", { hidden: !active }),
    localAudioClass: classNames("ots-video-control circle audio", {
      hidden: !active,
      muted: !localAudioEnabled,
    }),
    localVideoClass: classNames("ots-video-control circle video", {
      hidden: !active,
      muted: !localVideoEnabled,
    }),
    localCallClass: classNames("ots-video-control circle end-call", {
      hidden: !active,
    }),
    cameraPublisherClass: classNames("video-container", {
      hidden: !active,
      small: !!activeCameraSubscribers || screenshareActive,
      left: screenshareActive,
    }),
    screenPublisherClass: classNames("video-container", {
      hidden: !active || !sharingScreen,
    }),
    cameraSubscriberClass: classNames(
      "video-container",
      { hidden: !active || !activeCameraSubscribers },
      { "active-gt2": activeCameraSubscribersGt2 && !screenshareActive },
      { "active-odd": activeCameraSubscribersOdd && !screenshareActive },
      { small: screenshareActive }
    ),
    screenSubscriberClass: classNames("video-container", {
      hidden: !viewingSharedScreen || !active,
    }),
  };
};

const connectingMask = () => (
  <div className="Video-mask">
    <Spinner />
    <div className="message with-spinner">Connecting</div>
  </div>
);

const startCallMask = (start) => (
  <div className="Video-mask">
    <button className="message button clickable" onClick={start}>
      Click to Start Call
    </button>
  </div>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      active: false,
      publishers: null,
      subscribers: null,
      meta: null,
      localAudioEnabled: true,
      localVideoEnabled: true,
      starting: false,
      option: 0,
    };
    this.startCall = this.startCall.bind(this);
    this.endCall = this.endCall.bind(this);
    this.toggleLocalAudio = this.toggleLocalAudio.bind(this);
    this.toggleLocalVideo = this.toggleLocalVideo.bind(this);
  }

  componentDidMount() {
    axios
      .get(`/api/v1/opentok/room/${this.props.query.get("lessonID")}`)
      .then((res) => {
        otCoreOptions.credentials.apiKey = res.data.apiKey;
        otCoreOptions.credentials.sessionId = res.data.sessionId;
        otCoreOptions.credentials.token = res.data.token;
        otCore = new AccCore(otCoreOptions);
        otCore
          .connect()
          .then(() => {
            this.setState({ connected: true });
            this.startCall();
          })
          .catch((err) => console.log(err));
        const events = [
          "subscribeToCamera",
          "unsubscribeFromCamera",
          "subscribeToScreen",
          "unsubscribeFromScreen",
          "startScreenShare",
          "endScreenShare",
        ];

        events.forEach((event) =>
          otCore.on(event, ({ publishers, subscribers, meta }) => {
            this.setState({ publishers, subscribers, meta });
            if (event === "startScreenShare") {
              if (this.props.view === 0) {
                this.props.setView(1, true);
                this.props.setScreenSharing(true);
              }
              // change tab, add new one, who is subscriber and who is publisher?
            } else if (event === "endScreenShare") {
              this.props.setView(0, true);
              this.props.setScreenSharing(false);
            }
          })
        );
      })
      .catch((err) => console.log(err));
  }

  startCall() {
    this.setState({ loading: true });
    otCore
      .startCall()
      .then(({ publishers, subscribers, meta }) => {
        this.setState({
          publishers,
          subscribers,
          meta,
          active: true,
          loading: false,
        });
      })
      .catch((error) => console.log(error));
  }

  endCall() {
    otCore.endCall();
    // some addition actions
    this.setState({ active: false });
  }

  toggleLocalAudio() {
    otCore.toggleLocalAudio(!this.state.localAudioEnabled);
    this.setState({ localAudioEnabled: !this.state.localAudioEnabled });
  }

  toggleLocalVideo() {
    otCore.toggleLocalVideo(!this.state.localVideoEnabled);
    this.setState({ localVideoEnabled: !this.state.localVideoEnabled });
  }

  handleChangeOption = (e, value) => {
    this.setState({ option: value });
  };

  render() {
    const { connected, active, loading } = this.state;
    const { classes } = this.props;
    const {
      localAudioClass,
      localVideoClass,
      localCallClass,
      controlClass,
      cameraPublisherClass,
      screenPublisherClass,
      cameraSubscriberClass,
      screenSubscriberClass,
    } = containerClasses(this.state);

    return (
      <div className={`video__${this.props.view}`}>
        {this.props.view === 0 && (
          <Tabs
            value={this.state.option}
            onChange={this.handleChangeOption}
            aria-label="simple tabs example"
            className={classes.tabs}
            variant={"fullWidth"}
          >
            <Tab label="Video" {...a11yProps(0)} className={classes.tab} />
            <Tab label="Messenger" {...a11yProps(1)} className={classes.tab} />
          </Tabs>
        )}
        <React.Fragment>
          <div
            className={`Video__${this.props.view}`}
            style={
              this.state.option === 1
                ? { visibility: "hidden", height: 0 }
                : { visibility: "visible" }
            }
          >
            <div
              className={`Video-main__${this.props.view}`}
              style={
                this.props.visibility && this.state.option === 0
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            >
              <div className={`Video-video-container__${this.props.view}`}>
                <div
                  className={
                    this.props.view === 0
                      ? `video__${this.props.view}__publisher`
                      : cameraPublisherClass
                  }
                  id="cameraPublisherContainer"
                >
                  <React.Fragment>
                    {!connected && connectingMask()}
                    {connected &&
                      !active &&
                      !loading &&
                      startCallMask(this.startCall)}
                    <div id="controls" className={controlClass}>
                      <div
                        className={localAudioClass}
                        onClick={this.toggleLocalAudio}
                      />
                      <div
                        className={localVideoClass}
                        onClick={this.toggleLocalVideo}
                      />
                      <div className={localCallClass} onClick={this.endCall} />
                    </div>
                  </React.Fragment>
                </div>
                {this.props.view === 1 && !connected && connectingMask()}
                {this.props.view === 1 &&
                  connected &&
                  !active &&
                  !loading &&
                  startCallMask(this.startCall)}

                <div
                  className={
                    this.props.view === 0
                      ? `video__${this.props.view}__subscriber`
                      : cameraSubscriberClass
                  }
                  id="cameraSubscriberContainer"
                >
                  {isEmpty(this.state.subscribers) ||
                  (!isEmpty(this.state.subscribers) &&
                    isEmpty(this.state.subscribers.camera)) ? (
                    <p>Waiting for another user to connect...</p>
                  ) : (
                    <div />
                  )}
                </div>
                {this.props.view === 1 && (
                  <React.Fragment>
                    <div
                      id="screenSubscriberContainer"
                      className={screenSubscriberClass}
                    />
                    <div
                      id="screenPublisherContainer"
                      className={screenPublisherClass}
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>

          {this.props.view === 0 && this.state.option === 1 ? (
            <Messenger />
          ) : (
            this.props.view === 1 && this.props.chat && <PopIcon />
          )}
        </React.Fragment>
        {/* ) : this.props.view === 1 ? ( */}
        {/* <React.Fragment>
            <div className="Video">
              <div className="Video-main">
                <div className="Video-video-container">
                  {!connected && connectingMask()}
                  {connected &&
                    !active &&
                    !loading &&
                    startCallMask(this.startCall)}
                  <div
                    id="cameraPublisherContainer"
                    className={cameraPublisherClass}
                  />
                  <div
                    id="screenPublisherContainer"
                    className={screenPublisherClass}
                  />
                  <div
                    id="cameraSubscriberContainer"
                    className={cameraSubscriberClass}
                  />
                  <div
                    id="screenSubscriberContainer"
                    className={screenSubscriberClass}
                  />
                  <PopIcon />
                </div>
                <div id="controls" className={controlClass}>
                  <div
                    className={localAudioClass}
                    onClick={this.toggleLocalAudio}
                  />
                  <div
                    className={localVideoClass}
                    onClick={this.toggleLocalVideo}
                  />
                  <div className={localCallClass} onClick={this.endCall} />
                </div>
              </div>
            </div>
          </React.Fragment> */}
        {/* ) : (
          <div />
        )} */}
      </div>
    );
  }
}

Video.defaultProps = {
  chat: true,
  visibility: true,
};

export default withStyles(styles)(Video);
