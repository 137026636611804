import {
  Button,
  TextField,
  makeStyles,
  CardContent,
  Card,
} from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import isEmpty from "../../validation/is-empty";
import "./docs.styles.scss";
import DocsLogo from "../../assets/docs-logo.png";
import { connect } from "react-redux";
import socket from "../../socket";
import Spinner from "../../components/spinner/Spinner";
import { addFlashMessage } from "../../redux/flash/flash.actions";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "rgba(27, 134, 144, 0.4)",
    color: "white",
    marginTop: 10,
    marginLeft: 7,
  },
  buttonback: {
    backgroundColor: "rgba(27, 134, 144, 0.4)",
    color: "white",
    margin: "10px 0 10px 10px",
    width: 140,
  },
}));
const GoogleDocs = (props) => {
  const [loading, setLoading] = useState();
  const [file, setFile] = useState();
  const [path, setPath] = useState();
  const [link, setLink] = useState();
  const [page, setPage] = useState(0);
  let classes = useStyles();

  const { addFlashMessage } = props;

  const createDocFile = () => {
    setLoading(true);
    axios
      .post("/api/v1/google/docs/file", props?.data?.data)
      .then((res) => {
        if (res.data) {
          socket.emit("updateLesson", props?.data?.data);
          setLoading(false);
          addFlashMessage({
            text: `Google docs created`,
            type: "success",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const addDocFile = () => {
    setLoading(true);
    axios
      .post("/api/v1/google/docs/link", {
        data: props?.data?.data,
        url: link,
        title: "Google Doc",
        type: "Google",
      })
      .then((res) => {
        if (res.data) {
          socket.emit("updateLesson", props?.data?.data);
          setLoading(false);
          setLink("");
          addFlashMessage({
            text: `Google docs created`,
            type: "success",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className="docs"
      style={
        !isEmpty(path)
          ? { height: props.height, padding: 0 }
          : { height: props.height }
      }
    >
      {!isEmpty(path) && page === 1 ? (
        <div style={{ width: "100%", height: "100%" }}>
          <div className="docs--nav">
            {" "}
            <Button
              className={classes.buttonback}
              s
              onClick={(e) => setPage(0)}
            >
              {" "}
              Go back{" "}
            </Button>
          </div>
          <iframe
            id="frame"
            frameBorder="0"
            title="Inline Frame Example"
            width="100%"
            height="95%"
            src={path}
          ></iframe>
        </div>
      ) : (
        <div className="docs__container">
          <Card>
            <CardContent>
              <div className="docs__container">
                <p>Embeded your own file</p>
                <div className="docs__container--container">
                  <TextField
                    className="docs__input"
                    multiline="true"
                    style={{ minWidth: 270 }}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="Link to a google docs document"
                  />
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => addDocFile()}
                  >
                    {" "}
                    Add{" "}
                  </Button>
                </div>
                <p>or</p>
                <div className="docs__create">
                  <p>Create a new file automatically</p>
                  <Button
                    onClick={createDocFile}
                    variant="contained"
                    className={classes.button}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
          {loading && <Spinner />}{" "}
          <div className="docs--files">
            <div className="docs--files--title"> Uploaded docs:</div>
            <div className="docs--files__container">
              {props.lesson?.lesson?.materials?.map((material) => {
                if (material.type === "Google") {
                  return (
                    <Button>
                      <Card
                        onClick={() => {
                          setPath(material.url);
                          setPage(1);
                        }}
                      >
                        <CardContent>
                          <div className="docs--files--file">
                            <img src={DocsLogo} className="docs--files--doc" />
                            <p className="docs--files--name">Google File</p>
                          </div>
                        </CardContent>
                      </Card>
                    </Button>
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.data,
  setting: state.setting,
  lesson: state.lesson,
  message: state.message,
});

const mapDispatchToProps = (dispatch) => ({
  addFlashMessage: (data) => dispatch(addFlashMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleDocs);
