import { Button, IconButton, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import "../messenger/messenger.styles.scss";
import { connect } from "react-redux";
import isEmpty from "../../validation/is-empty";
import CancelIcon from "@material-ui/icons/Cancel";
import socket from "../../socket";

const useStyles = makeStyles((theme) => ({
  messenger: {
    width: "27%",
    height: "100%",
    position: "absolute",
    overflow: "hidden",
    left: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: "#ffff",
    padding: 15,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  body: {
    height: "100%",
    overflow: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 80,
    paddingTop: 97,
  },
  input: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    height: "80px",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(240, 240, 240, 1)",
  },
  top: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 67,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "white",
    zIndex: 200,
  },
  icon: {
    width: 30,
    height: 30,
    color: " #272d4f",
  },
}));
const MessengerVideo2 = (props) => {
  const classes = useStyles();
  const [message, setMessage] = useState("");

  const messagesEndRef = useRef(null);
  const { messages } = props.message;
  const { data } = props.data;

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (!isEmpty(messagesEndRef.current)) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendKey = (e) => {
    if (e.charCode === 13) {
      sendMessage(e);
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const messageObject = {
      // lessonID: data.lessonID,
      // author: data.userID,
      // authorName: data.userName,
      type: "text",
      text: message,
      ...data,
    };
    setMessage("");
    socket.emit("msg", messageObject);
  };

  return (
    <div className={classes.messenger}>
      <div className={classes.top}>
        <IconButton onClick={props.closeChat}>
          <CancelIcon className={classes.icon} />
        </IconButton>
      </div>
      <div className={classes.body}>
        {!isEmpty(messages) &&
          messages.map((msg) =>
            msg.author !== data.userID ? (
              <div className="messenger__msg--container" key={msg._id}>
                <div className="messenger__author">{msg.authorName}</div>
                <div className="messenger__msg">{msg.text}</div>
              </div>
            ) : (
              <div className="messenger__msgpartner--container" key={msg._id}>
                <div className="messenger__partnerauthor">{msg.authorName}</div>
                <div className="messenger__msgpartner">{msg.text}</div>
              </div>
            )
          )}
        <div ref={messagesEndRef} />
      </div>
      <div className={classes.input}>
        <input
          className="messenger__input--input"
          className="messenger__input--input"
          multiline="true"
          id="outlined-adornment-password"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={sendKey}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<SendIcon />}
          onClick={(e) => sendMessage(e)}
        >
          Send
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  message: state.message,
  data: state.data,
});

export default connect(mapStateToProps)(MessengerVideo2);
