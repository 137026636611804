import React, { useState, useEffect } from "react";
import { Button, Card } from "@material-ui/core";
import socket from "../../socket";

const MaterialPreview = (props) => {
  const { material, img } = props;
  useEffect(() => {
    cutTitle();
  }, []);
  let title = material.title;
  const cutTitle = () => {
    if (material.title > 30) {
      return title.substring(0, 30);
    }
  };
  return (
    <div className="materials__itemscontainer">
      <Card className="materials__preview">
        <div className="materials__preview--img--container">
          <img src={img} className="materials__preview--img" />
        </div>
        <p className="materials__preview--title">{title}</p>
      </Card>
    </div>
  );
};
export default MaterialPreview;
