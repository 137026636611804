import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Whiteboard from "../../whiteboard";
import GoogleDocs from "../materials/GoogleDocs";
import Materials from "../materials/Materials";
import Video from "../video/";
import Articles from "../articles/Articles";
import isEmpty from "../../validation/is-empty";
import Quiz from "../quiz";
import Messenger from "../messenger/Messenger";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    height: "100%",
  },
  tabs: {
    backgroundColor: "white",
    borderBottom: "1px solid lightgray",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  tab: {
    color: theme.palette.primary,
  },
  tabPanel: {
    width: "100%",
    padding: 0,
    margin: 0,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    "& .MuiBox-root": {
      padding: "0px",
    },
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();

  const { card, setCard } = props;

  const [option, setOption] = useState(0);

  const handleChange = (event, newValue) => {
    setCard(newValue);
  };
  const handleChangeOption = (event, newValue) => {
    setOption(newValue);
  };

  return (
    <div className={classes.root}>
      {window.innerWidth < 900 && (
        <React.Fragment>
          <Tabs
            value={option}
            onChange={handleChangeOption}
            aria-label="simple tabs example"
            className={classes.tabs}
            variant={"fullWidth"}
          >
            <Tab className={classes.tab} label="Video" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Messenger" {...a11yProps(1)} />
            <Tab
              className={classes.tab}
              label="Class Tools"
              {...a11yProps(2)}
            />
          </Tabs>
          <div
            style={{
              height: option === 0 ? props.height - 55 : 0,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              visibility: option === 0 ? "visible" : "hidden",
            }}
          >
            <Video
              query={props.query}
              view={1}
              setCard={setCard}
              chat={false}
              visibility={option === 0 ? true : false}
            />
          </div>
          <div
            style={{
              height: option === 1 ? props.height - 55 : 0,
              visibility: option === 1 ? "visible" : "hidden",
              width: "100%",
            }}
          >
            <Messenger />
          </div>
        </React.Fragment>
      )}

      {(option === 2 || window.innerWidth > 900) && (
        <React.Fragment>
          <Tabs
            value={card}
            onChange={handleChange}
            aria-label="simple tabs example"
            className={classes.tabs}
            variant={"scrollable"}
          >
            {!isEmpty(props.lesson?.lesson?.article) && (
              <Tab className={classes.tab} label="Article" {...a11yProps(0)} />
            )}
            <Tab
              className={classes.tab}
              label="Whiteboard"
              {...a11yProps(isEmpty(props.lesson?.lesson?.article) ? 0 : 1)}
            />
            <Tab
              className={classes.tab}
              label="File sharing"
              {...a11yProps(isEmpty(props.lesson?.lesson?.article) ? 1 : 2)}
            />
            <Tab
              className={classes.tab}
              label="Google docs"
              {...a11yProps(isEmpty(props.lesson?.lesson?.article) ? 2 : 3)}
            />
            {!isEmpty(props.lesson?.lesson?.article) && (
              <Tab className={classes.tab} label="Quiz" {...a11yProps(4)} />
            )}
            {props.screenSharing && card === 5 && (
              <Tab
                className={classes.tab}
                label="Screen sharing"
                {...a11yProps(5)}
              />
            )}
          </Tabs>
          {!isEmpty(props.lesson?.lesson?.article) && (
            <TabPanel value={card} index={0} className={classes.tabPanel}>
              <Articles
                height={props.height - 55}
                slug={props.lesson?.lesson?.article?.slug}
                window={window}
              />
            </TabPanel>
          )}
          <TabPanel
            value={card}
            index={isEmpty(props.lesson?.lesson?.article) ? 0 : 1}
            className={classes.tabPanel}
          >
            <Whiteboard
              width={props.width - 3}
              height={props.height - 55}
              query={props.query}
            />
          </TabPanel>
          <TabPanel
            value={card}
            index={isEmpty(props.lesson?.lesson?.article) ? 1 : 2}
            className={classes.tabPanel}
            style={{ height: props.height - 49, overflow: "scroll" }}
          >
            <Materials />
          </TabPanel>
          <TabPanel
            value={card}
            index={isEmpty(props.lesson?.lesson?.article) ? 2 : 3}
            className={classes.tabPanel}
            style={{ height: props.height - 49 }}
          >
            <GoogleDocs height={props.height - 49} />
          </TabPanel>
          {!isEmpty(props.lesson?.lesson?.article) && (
            <TabPanel
              value={card}
              index={4}
              className={classes.tabPanel}
              style={{ height: props.height - 49 }}
            >
              <Quiz
                height={props.height - 49}
                slug={props.lesson?.lesson?.article?.slug}
              />
            </TabPanel>
          )}
          {card === 5 && (
            <TabPanel
              value={card}
              index={3}
              className={classes.tabPanel}
              style={{ height: props.height - 49 }}
            >
              {props.screenSharing}
            </TabPanel>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
