import React from "react";

const Quiz = (props) => {
  const renderSwitch = (slug) => {
    switch (slug) {
      case "big-tech":
        return (
          <div style={{ height: `${props.height}px` }}>
            <iframe
              id="typeform-full"
              width="100%"
              height="100%"
              frameborder="0"
              allow="camera; microphone; autoplay; encrypted-media;"
              src={
                "https://form.typeform.com/to/wQ3p5UIp?typeform-medium=embed-snippet"
              }
            ></iframe>
          </div>
        );

      case "climate-change":
        return (
          <iframe
            id="typeform-full"
            width="100%"
            height="100%"
            frameborder="0"
            allow="camera; microphone; autoplay; encrypted-media;"
            src={
              "https://form.typeform.com/to/wQ3p5UIp?typeform-medium=embed-snippet"
            }
          ></iframe>
        );
      case "cyber-war":
        return (
          <iframe
            id="typeform-full"
            width="100%"
            height="100%"
            frameborder="0"
            allow="camera; microphone; autoplay; encrypted-media;"
            src={
              "https://form.typeform.com/to/wQ3p5UIp?typeform-medium=embed-snippet"
            }
          ></iframe>
        );

      case "word-of-work":
        return (
          <iframe
            id="typeform-full"
            width="100%"
            height="100%"
            frameborder="0"
            allow="camera; microphone; autoplay; encrypted-media;"
            src={
              "https://form.typeform.com/to/HHmeuSWa?typeform-medium=embed-snippet"
            }
          ></iframe>
        );

      case "covid-vaccine":
        return (
          <iframe
            id="typeform-full"
            width="100%"
            height="100%"
            frameborder="0"
            allow="camera; microphone; autoplay; encrypted-media;"
            src={
              "https://form.typeform.com/to/wQ3p5UIp?typeform-medium=embed-snippet"
            }
          ></iframe>
        );

      default:
        return (
          <iframe
            id="typeform-full"
            width="100%"
            height="100%"
            frameborder="0"
            allow="camera; microphone; autoplay; encrypted-media;"
            src={
              "https://form.typeform.com/to/wQ3p5UIp?typeform-medium=embed-snippet"
            }
          ></iframe>
        );
    }
  };

  return (
    <div style={{ width: "100%", height: props.height, overflow: "auto" }}>
      {renderSwitch(props.slug)}
    </div>
  );
};

export default Quiz;
