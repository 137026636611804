import { Button, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import "./messenger.styles.scss";
import { connect } from "react-redux";
import isEmpty from "../../validation/is-empty";
import socket from "../../socket";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const Messenger = (props) => {
  const classes = useStyles();
  const [message, setMessage] = useState("");

  const messagesEndRef = useRef(null);
  const { messages } = props.message;
  const { data } = props.data;

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const scrollToBottom = () => {
    if (!isEmpty(messagesEndRef.current)) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const sendKey = (e) => {
    if (e.charCode === 13) {
      sendMessage(e);
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const messageObject = {
      // lessonID: data.lessonID,
      // author: data.userID,
      // authorName: data.userName,
      type: "text",
      text: message,
      ...data,
    };
    setMessage("");
    socket.emit("msg", messageObject);
  };

  return (
    <div className="messenger">
      <div className="messenger--body">
        {!isEmpty(messages) &&
          messages.map((msg) =>
            msg.author !== data.userID ? (
              <div className="messenger__msg--container" key={msg._id}>
                <div className="messenger__author">{msg.authorName}</div>
                <div className="messenger__msg">{msg.text}</div>
              </div>
            ) : (
              <div className="messenger__msgpartner--container" key={msg._id}>
                <div className="messenger__partnerauthor">{msg.authorName}</div>
                <div className="messenger__msgpartner">{msg.text}</div>
              </div>
            )
          )}
        <div ref={messagesEndRef} />
      </div>
      <div className="messenger__input">
        <input
          className="messenger__input--input"
          multiline="true"
          id="outlined-adornment-password"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={sendKey}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<SendIcon />}
          onClick={(e) => sendMessage(e)}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  message: state.message,
  data: state.data,
});

export default connect(mapStateToProps)(Messenger);
