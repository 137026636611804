import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  makeStyles,
  Grid,
  Box,
  Divider,
  Button,
  Avatar,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Select,
  MenuItem,
} from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import MicIcon from "@material-ui/icons/Mic";
import isEmpty from "../../validation/is-empty";
import Slide from "@material-ui/core/Slide";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {},
  avatar: {
    width: 200,
    height: 120,
  },
  buttonCancel: {
    color: theme.palette.common.red,
    borderColor: theme.palette.common.red,
    margin: "0 5px",
    "&:hover": {
      color: theme.palette.common.red,
      borderColor: theme.palette.common.red,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      width: "auto",
      margin: "0 3px",
    },
  },
  buttonSuccess: {
    backgroundColor: theme.palette.common.green,
    margin: "0 5px",
    "&:hover": {
      backgroundColor: theme.palette.common.green,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      width: "auto",
      margin: "0 3px",
    },
  },
  button: {
    margin: "0 5px",
    backgroundColor: "#4CAF50",
    color: "white",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      width: "auto",
      margin: "0 3px",
    },
  },
  span: {
    color: theme.palette.common.blue,
  },
  spanRed: {
    color: theme.palette.common.red,
  },
  spanGreen: {
    color: theme.palette.common.red,
  },
  card: {
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px",
    },
  },
}));

const Popup1 = ({ onClose, open, nextStep }) => {
  const [camera, setCamera] = useState(false);
  const [mic, setMic] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  useEffect(() => {
    checkIfPermissionGranted();
    return () => {};
  }, []);

  const checkIfPermissionGranted = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        {
          if (stream.getVideoTracks().length > 0) {
            setCamera(true);
          } else {
            setCamera(false);
          }
          if (stream.getAudioTracks().length > 0) {
            setMic(true);
          } else {
            setMic(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="confirm-lesson"
      open={open}
      maxWidth="lg"
      classes={{
        paper: classes.container,
        root: classes.background,
      }}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      TransitionComponent={Transition}
    >
      <DialogTitle id="confirmation-dialog-title" disableTypography={true}>
        <h4 className="class__popup--title">Welcome to Nativated Class</h4>
      </DialogTitle>
      <DialogContent dividers>
        <p className="class__popup--label">Let's review your setup:</p>
        <div className="class__popup__icons">
          <div style={{ textAlign: "center" }}>
            {mic ? (
              <MicIcon className="class__popup--icon" />
            ) : (
              <MicIcon className="class__popup--icon-error" />
            )}
            <p>Microphone</p>
          </div>
          <div style={{ textAlign: "center" }}>
            {camera ? (
              <VideocamIcon className="class__popup--icon" />
            ) : (
              <VideocamIcon className="class__popup--icon-error" />
            )}
            <p>Camera</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => nextStep(2)}
          size="large"
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default Popup1;
