import React, { Component } from "react";
import PdfViewer from "./PdfViewer/PdfViewer";
import PDFJSBackend from "./PdfViewer/backends/pdfjs";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
class PrivacyPolicy extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  };

  render() {
    return (
        <div className="pdf">
            <div className="pdf__topcontainer">
               <IconButton style={{color: "rgba(27, 134, 144, 0.4)"}} onClick={(e) => this.props.closePdf(null)}><CloseIcon/></IconButton>
            </div>
          <PdfViewer backend={PDFJSBackend} src={this.props.url} />
        </div>
    );
  }
}
export default PrivacyPolicy;
