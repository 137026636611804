import React from "react";
import BigTech from "./march/big-tech";
import ClimateChange from "./march/climate-change";
import CovidVaccine from "./march/covid-vaccine";
import CyberWar from "./march/cyber-war";
import WordOfWork from "./march/word-of-work";
import "./blog.scss";
const Articles = (props) => {
  const renderSwitch = (slug) => {
    switch (slug) {
      case "big-tech":
        return <BigTech />;

      case "climate-change":
        return <ClimateChange />;
      case "cyber-war":
        return <CyberWar />;

      case "word-of-work":
        return <WordOfWork />;

      case "covid-vaccine":
        return <CovidVaccine />;

      default:
        return <BigTech />;
    }
  };

  return (
    <div style={{ width: "100%", height: props.height, overflow: "auto" }}>
      {renderSwitch(props.slug)}
    </div>
  );
};

export default Articles;
